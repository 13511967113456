export const INPUT_LIMITS = {
  NAME: 30,
  EMAIL: 100,
  ADDRESS: 100,
  ZIP: 10,
  PHONE: 15,
};

export const VIDEO_INPUT_LIMITS = {
  NAME: 30,
};
