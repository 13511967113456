import videojs from 'video.js';
import 'video.js/dist/video-js.css'; // Ensure Video.js styles are imported

class CustomButton extends videojs.getComponent('Button') {
  constructor(player, options) {
    super(player, options);
    this.addClass('vjs-custom-button');
    this.addClass('vjs-control');
    this.addClass('vjs-button');

    // Save the custom function from options
    this.customFunction = options.customFunction || this.defaultFunction;

    this.on('click', this.handleClick);
  }

  handleClick() {
    console.log('Custom button clicked');
    this.customFunction(); // Call the custom function
  }

  // Default function if no custom function is provided
  defaultFunction() {
    console.log('Default custom function');
  }
}

// Register the component with Video.js
videojs.registerComponent('CustomButton', CustomButton);

export default CustomButton;
