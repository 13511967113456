import videojs from "video.js";
import "video.js/dist/video-js.css";

// Define the custom seekbar component
class CustomSeekBar extends videojs.getComponent("ProgressControl") {
  constructor(player, options) {
    super(player, options);

    // Create a container for the preview
    this.previewContainer = videojs.dom.createEl("div", {
      className: "vjs-seekbar-preview",
    });

    // Create the preview video element
    this.previewVideo = videojs.dom.createEl("video", {
      className: "vjs-preview-video",
      controls: false, // Hide controls for the preview
      preload: "auto", // Preload the video
    });
    this.previewContainer.appendChild(this.previewVideo);

    // Add the preview container to the seek bar
    this.el().appendChild(this.previewContainer);

    // Bind methods to `this`
    this.showPreview = this.showPreview.bind(this);
    this.hidePreview = this.hidePreview.bind(this);

    // Add event listeners for showing and hiding the preview
    this.on("mousemove", this.showPreview);
    this.on("mouseout", this.hidePreview);

    // Set the preview video source
    this.previewVideo.src = options.url;

    // Add comment markers
    this.comments = options.comments || [];
    this.createCommentMarkers();

    // Listen for the loadedmetadata event to ensure the duration is available
    this.player().on("loadedmetadata", this.updateDuration.bind(this));
  }

  // createCommentMarkers() {
  //   this.commentMarkers = videojs.dom.createEl("div", {
  //     className: "vjs-comment-markers",
  //   });
  //   this.el().appendChild(this.commentMarkers);

  //   this.comments.forEach((comment) => {
  //     const marker = videojs.dom.createEl("div", {
  //       className: "vjs-comment-marker",
  //       title: comment.message,
  //     });

  //     marker.style.left = `${(comment.time / this.player().duration()) * 100}%`;
  //     this.commentMarkers.appendChild(marker);
  //   });
  // }

  // Update the comment markers
  updateComments(comments) {
    this.comments = comments;
    this.createCommentMarkers();
  }

  updateDuration() {
    this.createCommentMarkers(); // Create markers once the duration is available
  }

  createCommentMarkers() {
    if (!this.player().duration()) {
      // Duration not available yet
      return;
    }

    // Remove old markers if they exist
    if (this.commentMarkers) {
      this.commentMarkers.remove();
    }

    this.commentMarkers = videojs.dom.createEl("div", {
      className: "vjs-comment-markers",
    });
    this.el().appendChild(this.commentMarkers);

    this.comments.forEach((comment) => {
      const marker = videojs.dom.createEl("div", {
        className: "vjs-comment-marker",
        title: comment.comment,
      });
      marker.style.left = `${
        (comment.time_sec / this.player().duration()) * 100
      }%`;
      this.commentMarkers.appendChild(marker);
    });
  }

  // showPreview(event) {
  //   const rect = this.el().getBoundingClientRect();
  //   const x = event.clientX - rect.left;
  //   const percent = x / this.el().offsetWidth;
  //   const previewVideo = this.previewVideo;

  //   // Update the current time of the preview video based on mouse position
  //   previewVideo.currentTime = this.player().duration() * percent;

  //   // Position and display the preview container
  //   this.previewContainer.style.left = `${x}px`;
  //   this.previewContainer.style.display = "block";
  // }

  showPreview(event) {
    const el = this.el();
    if (!el) {
      console.error("Element is not available.");
      return;
    }

    const rect = el.getBoundingClientRect();
    if (typeof event.clientX !== "number") {
      console.error("Invalid event object.");
      return;
    }

    const x = event.clientX - rect.left;
    const percent = x / el.offsetWidth;

    const previewVideo = this.previewVideo;
    if (!previewVideo || typeof previewVideo.currentTime === "undefined") {
      console.error("Preview video is not available.");
      return;
    }

    const player = this.player();
    if (!player || typeof player.duration !== "function") {
      console.error(
        "Player is not available or does not have a duration method."
      );
      return;
    }

    const duration = player.duration();
    if (typeof duration !== "number" || duration < 0) {
      console.error("Invalid duration.");
      return;
    }

    previewVideo.currentTime = duration * percent;

    const previewContainer = this.previewContainer;
    if (!previewContainer || typeof previewContainer.style === "undefined") {
      console.error("Preview container is not available.");
      return;
    }

    previewContainer.style.left = `${x}px`;
    previewContainer.style.display = "block";
  }

  hidePreview() {
    this.previewContainer.style.display = "none";
  }
}

// Register the custom seekbar component
videojs.registerComponent("CustomSeekBar", CustomSeekBar);

export default CustomSeekBar;
