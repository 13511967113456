import React, { useState, useEffect, useContext } from "react";
import { Box, Checkbox, ConfirmDialog, Snackbar } from "components";
import { ReactComponent as DownloadIcon } from "assets/images/video-download-outlined.svg";
import VideoPlayer from "../video-player/video-player";
import RenamePopup from "../rename-popup/rename-popup";
import thumbnail_default from "assets/images/thumbnail.png";
import styles from "../freelancer-info.module.scss";
import AuthServices from "api/services/auth-services";
import { ReactComponent as EditIcon } from "assets/images/video-edit-outlined.svg";
import { ReactComponent as TrashIcon } from "assets/images/video-delete-outlined.svg";
import { ReactComponent as CheckIcon } from "assets/images/white-check.svg";
import { ReactComponent as Decline } from "assets/images/decline.svg";
import { ReactComponent as GreenCheckIcon } from "assets/images/green_checkbox.svg";

import moment from 'moment-timezone';
import { Stack } from "@mui/material";
import { simulatePostApi } from "app/downloadfn";
import { actions, GlobalContext } from "context";
import { checkBoolean, convertSecondsToHMS } from "utils/videoUtils";
import { getErrorMessage } from "utils/validator";

const VideoCard = ({
  info,
  selectedVideos,
  setSelectedVideos,
  selectAll,
  index,
  isBulkSelect,
  getFreelancerDetails,
  unselectAll,
  setUnSelectAll,
  freelancerDetail,
  // deleteVideos,
  showPlayer = true,
}) => {
  const {
    dispatch,
    state: { isAdmin },
  } = useContext(GlobalContext);
  const IS_ADMIN = checkBoolean(isAdmin);
  const [showVideo, setShowVideo] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [select, setSelect] = useState(false);
  const [videoUrl, setvideoUrl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({
    message: "",
    class: "error",
  });
  const { file_name, duration, thumbnail, date, id, approval } = info || {};
  useEffect(() => {
    if (selectAll) {
      setSelect(true);
    } else {
      setSelect(false);
    }
  }, [selectAll]);

  useEffect(() => {
    !isBulkSelect && setSelect(false);
  }, [isBulkSelect]);

  useEffect(() => {
    if (unselectAll) {
      setSelect(false);
    }
  }, [unselectAll]);

  const selectVideo = (event) => {
    const checked = event.target.checked;
    event?.stopPropagation();
    setSelect(checked);
    unselectAll && setUnSelectAll(false);
    let videos = [...selectedVideos];
    if (checked) {
      videos.push(id);
    } else {
      videos = [...videos.filter((item) => item !== id)];
    }
    setSelectedVideos(videos);
  };

  const handleClose = (event, reason, showPrompt) => {
    setShowPopup(false);
  };

  const showVideoPlayer = async (e) => {
    if (!showPlayer) {
      return;
    }
    storeHandler(actions.SHOW_LOADER, true);
    try {
      console.log(info, "ONTO");
      const data = await simulatePostApi(info);
      storeHandler(actions.SHOW_LOADER, false);
      setvideoUrl(data?.[0]);
      setShowVideo(true);
    } catch (error) {
      console.log(error);
      storeHandler(actions.SHOW_LOADER, false);
    }
  };

  const deleteVideo = async (e) => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      await AuthServices.deleteVideo({ id: [info?.id] });
      storeHandler(actions.SHOW_LOADER, false);
      getFreelancerDetails(info?.id);
    } catch (error) {
      storeHandler(actions.SHOW_LOADER, false);
      const ERROR_MSG = getErrorMessage(error);
      setShowSnackBar(true);
      const content = {
        message: ERROR_MSG,
        class: "error",
      };
      setSnackBarContent({ ...content });
    }
  };

  const handleAddVideo = (url) => {
    const currentDownloads =
      JSON.parse(localStorage.getItem("downloadQueue")) || [];
    currentDownloads.push(...url);
    localStorage.setItem("downloadQueue", JSON.stringify(currentDownloads));
    const event = new CustomEvent("localStorageChange", { key: Date.now() });
    window.dispatchEvent(event);
  };

  const downloadVideo = async (e) => {
    e?.stopPropagation();

    const groupName = `Group_${Date.now()}`;
    const filteredArr = [{ ...(info || {}) }];
    filteredArr.forEach((item) => {
      item.group_name = groupName;
      item.completed = false;
      item.error = false;
    });
    let GROUPS = JSON.parse(localStorage.getItem("video_group")) || [];
    GROUPS.push(...filteredArr);

    localStorage.setItem("video_group", JSON.stringify(GROUPS));
    handleAddVideo(filteredArr);
  };

  const onConfirm = () => {
    deleteVideo();
    setOpenDialog(false);
  };

  const handImgLoadError = (event) => {
    event.target.src = thumbnail_default;
  };

  const storeHandler = (type, payload) => dispatch({ type, payload });

  return (
    <>
      <div className={`${styles.videoCard}`} style={{ height: "100%" }}>
        <div className={`${styles.videoPreview}`}>
          <img
            src={info?.thumbnail || thumbnail_default}
            alt="preview"
            className={`${styles.preview} cursor`}
            loading="lazy"
            onError={handImgLoadError}
          />
          <div
            className={`${styles.videoPreviewOverlay} d-flex align-items-start cursor`}
            onClick={showVideoPlayer}
          >
            <div
              className="d-flex justify-content-between flex-grow-1"
              style={{ height: "2rem" }}
            >
              <div className={`${styles.check}`}>
                {showPlayer && IS_ADMIN && (
                  <div style={{ transform: "scale(1.3)" }}>
                    <Checkbox
                      checkedIcon={
                        <GreenCheckIcon
                          style={{ width: "15px", height: "15px" }}
                        />
                      }
                      checked={select}
                      onClick={selectVideo}
                      id={`${index}-video`}
                    />
                  </div>
                )}
                <div className="txt-grey d-flex align-items-center txt-md">
                  {info?.approval === true && <CheckIcon width={"1.4rem"} />}
                  {info?.approval === false && <Decline width={"1.4rem"} />}
                </div>
              </div>
              <div
                className="txt-white d-flex align-items-center txt-md"
                style={{
                  background: "var(--error-main)",
                  padding: "0 .3rem",
                  fontSize: ".7rem",
                }}
              >
                {convertSecondsToHMS(duration)}
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.videoCardBoxWidth}`}>
          <div
            className={`${styles.videoDate} txt-smd py-2 px-2 d-flex justify-content-between`}
            style={{ background: "var(--error-main)" }}
          >
            <div>
              <span className="txt-xs txt-white">
                {date ? moment(date).format("MM/DD/YYYY HH:mm (z)") : '-'}
              </span>
            </div>{" "}
            {IS_ADMIN && (
              <div className="d-flex justify-content-end">
                <TrashIcon
                  width={"1rem"}
                  height={"1rem"}
                  className="cursor"
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenDialog(true);
                  }}
                />
              </div>
            )}
          </div>
          <div
            className={`txt-smd py-2 px-2 d-flex justify-content-between`}
            style={{ background: "black" }}
          >
            <span
              style={{
                wordBreak: "break-all",
                paddingRight: "10px",
                color: "white",
              }}
            >
              {file_name}
            </span>
            {IS_ADMIN && (
              <Stack
                direction="row"
                gap={".5rem"}
                alignItems="center"
                justifyContent="center"
              >
                <EditIcon
                  width={"1rem"}
                  height={"1rem"}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowPopup(true);
                  }}  
                  className="cursor"
                />
                <Box
                  className={select ? "selected-videos" : "non-selected"}
                  style={{
                    position: "relative",
                    top: ".2rem",
                    cursor: "pointer",
                  }}
                >
                  <DownloadIcon
                    width={"1rem"}
                    height={"1rem"}
                    onClick={downloadVideo}
                  />
                </Box>
              </Stack>
            )}
          </div>
        </div>
      </div>
      {showPopup && (
        <RenamePopup
          freelancerDetail={freelancerDetail}
          filename={file_name}
          open={showPopup}
          id={info?.id}
          onConfirm={handleClose}
          getFreelancerDetails={getFreelancerDetails}
        />
      )}
      {showVideo && (
        <VideoPlayer
          closePreview={setShowVideo}
          video={videoUrl}
          name={file_name}
          thumbnail={thumbnail}
          id={id}
          approval={approval}
          refresh={getFreelancerDetails}
        />
      )}
      <ConfirmDialog
        isPrompt={false}
        open={openDialog}
        onClose={setOpenDialog}
        onConfirm={onConfirm}
        count={1}
        isDeleteVideo={true}
        customDelete={true}
      />

      <Snackbar
        open={showSnackBar}
        message={snackbarContent?.message || ""}
        className={snackbarContent?.class || ""}
        autoHideDuration={4000}
        closeSnackBar={setShowSnackBar}
      />
    </>
  );
};

export default VideoCard;
