import React, { useRef } from "react";
import styles from "../freelancer-info.module.scss";
import { ReactComponent as CancelIcon } from "assets/images/add-icon.svg";
import { VideoPreviewer } from "components";
import VP from "components/custom-video-player/VideoPlayer";

const VideoPlayer = ({ closePreview, video, name, thumbnail, id, approval, refresh }) => {
  return (
    <div
      className={`d-flex align-items-center justify-content-center ${styles.videoPreviewer}`}
    >
      <div className={styles.videoWrapper}>
        <div
          className={`${styles.close} txt-md`}
          onClick={() => closePreview(false)}
        >
          <CancelIcon
            style={{
              width: "100%",
              height: "100%",
              fill: "var(--error-main)",
              transform: "rotate(50deg)",
            }}
          />
        </div>
        <div className={`${styles.title} px-3 py-2 txt-md w-100`}>{name}</div>
        <VP
          video={video}
          thumbnail={thumbnail}
          approval={approval}
          id={id}
          name={name}
          outerComment={false}
          refresh={refresh}
          updateVideoApproval={refresh}
        />
      </div>
    </div>
  );
};

export default VideoPlayer;
