// Login APIs
export const LOGIN = "/create?func=login";
export const LOGOUT = "/create?func=logout";
export const USER_PROFILE = "/create?func=profile";
export const GET_OTP = "/create?func=forgot";
export const VERIFY_OTP = "/create?func=verify_otp";
export const RESET_PASSWORD = "/create?func=reset";
export const ACTIVATE_PLAYBACK = "/admin/playback";
export const PLAY_BLACK_LIMIT = "/admin/playback";
export const GET_PROFILE = "/create?func=profile";
export const REFRESH_TOKEN = "/create?func=refresh_token";

// Admin APIs
export const DASHBOARD = "/";
export const ADMIN = "/get/admins";
export const ADD_ADMIN = "/add/admin";
export const EDIT_ADMIN = "/edit/admin";
export const GET_ADMIN_DETAIL = "/get/admin";
export const ACTIVATE_ADMIN = "/admin/activate";
export const DEACTIVATE_ADMIN = "/admin/deactivate";

// Freelancer APIs
export const FREELANCER = "/?func=get_all_contractors";
export const GET_FREELANCER = "/?func=get_contractors";
export const ADD_FREELANCER = "create?func=add_contractor";
export const EDIT_FREELANCER = "/create?func=edit_contractor";
export const FREELANCER_DETAILS = "create?func=contractor_profile";
export const FREELANCER_VIDEOS = "create?func=contractor_videos";
export const ACTIVATE_FREELANCER = "create?func=activate_contractor";
export const DEACTIVATE_FREELANCER = "create?func=deactivate_contractor";
export const DELETE_FREELANCER = "/delete?func=delete_contractor";

//Video APIs
export const VIDEO_RENAME = "/create?func=rename_video";
export const VIDEO_DELETE = "/video/delete";

//Download APIs
export const STORAGE_VIDEO = "/create?func=storage_videos";
export const GENERATE_DOWNLOAD_URL = "/create?func=generate_download_urls";
export const DELETE_VIDEO =
  process.env.REACT_APP_RECENT_DELETE === "true"
    ? "/delete?func=move_to_trash"
    : "/delete?func=delete_video";

//User APIs
export const ALL_USER = "/?func=get_all_users";
export const GET_USER = "/?func=get_users";
export const USER_TIMELINE = "/create?func=get_timeline";
export const CREATE_USER = "/create?func=add_users";
export const GET_USER_DETAIL = "/get_user";
export const EDIT_USER = "/create?func=edit_user";
// export const DELETE_USER = "/create?func=edit_user";
export const ACTIVATE_USER = "/create?func=activate_user";
export const DEACTIVATE_USER = "/create?func=deactivate_user";
export const DELETE_USER = "/delete?func=delete_user";

//Gallery
export const GALLERY_YEAR = "/create?func=gallery";
export const ALL_CONTRACTOR_NAME = "/create?func=all_contractor_names";
export const USER_LOCATION_LIST = "/create?func=user_state_filter";
export const CONTRACTOR_LOCATION_LIST = "/create?func=cont_state_filter";

export const GALLERY_MONTH = "/create?func=year";
export const GALLERY_DAYS = "/create?func=month";
export const GALLERY_DAY = "/create?func=day";
export const GALLERY_LIST = "/create?func=gallery_list";
export const VIDEO_APPROVE_REJECT = "/create?func=video_approval";
export const ADD_VIDEO_COMMENT = "/create?func=add_comment";
export const EDIT_VIDEO_COMMENT = "/create?func=edit_video_comment";
export const DELETE_VIDEO_COMMENT = "/create?func=delete_video_comment";
export const VIDEO_COMMENT = "/create?func=get_video_comments";
export const COUNTRY_STATE = "/create?func=country_states";
export const GET_GALLERY_SETTING = "/create?func=get_gallery_settings";
export const GET_TIMEZONE = "/create?func=get_general_setting";
export const GET_ALL_TIMEZONE = "/create?func=get_all_timezones";
export const UPDATE_TIMEZONE = "/create?func=update_general_setting";
export const UPDATE_GALLERY_SETTING = "/create?func=update_gallery_setting";
export const GET_DELETED_VIDEO = "/create?func=get_deleted_videos";
export const GET_DELETED_VIDEO_COUNT = "/create?func=deleted_count";
export const DELETE_RECENT_DELETED_VIDEO = "/create?func=permanent_delete";
export const RESTORE_RECENT_DELETED_VIDEO = "/create?func=restore_videos";
