import React, { useContext, useEffect, useMemo, useState } from "react";
// import styles from "./Gallery.module.scss";
// import { Paper, Typography } from "@material-ui/core";
// import { actions, GlobalContext } from "context";
// import { Box, InputField, Snackbar } from "components";
// import AuthServices from "api/services/auth-services";
// import { Skeleton, Stack } from "@mui/material";
// import ButtonWithIcon from "components/IconButton/icon-button";
// import { ReactComponent as SortIcon } from "assets/images/sort.svg";
// import { ReactComponent as FilterIcon } from "assets/images/filter.svg";
// import YearCard from "./year-card/year-card";
// import { useHistory, useLocation } from "react-router-dom";
// import UserVideoList from "./video-detail/video-list";
// import Breadcrumb from "./breadcrumb/breadcrumb";
// import GalleryFilterPopover from "./gallery-filter-opover/GalleryFilterPopover";
// import GallerySortPopover from "./gallery-sort-popover/GallerySortPopover";
// import { simulatePostApi } from "app/downloadfn";
// import VideoPlayer from "components/custom-video-player/VideoPlayer";
import MyGallery from '../gallery-page/Gallery'

// function addKeyValueToNestedObjects(obj, key, value) {
//   if (typeof obj !== "object" || obj === null) {
//     throw new TypeError("The first argument must be a non-null object.");
//   }

//   if (typeof key !== "string" || key.trim() === "") {
//     throw new TypeError("The key must be a non-empty string.");
//   }

//   if (value === undefined) {
//     throw new TypeError("The value must be defined.");
//   }

//   const newObj = {};

//   for (const year in obj) {
//     if (obj.hasOwnProperty(year)) {
//       if (typeof obj[year] === "object" && obj[year] !== null) {
//         newObj[year] = { ...obj[year], [key]: value };
//       } else {
//         console.warn(`Skipping ${year}: Not an object.`);
//         newObj[year] = obj[year];
//       }
//     }
//   }

//   return newObj;
// }

// const monthNameToNumber = (monthName) => {
//   const monthMap = {
//     jan: 1,
//     feb: 2,
//     mar: 3,
//     apr: 4,
//     may: 5,
//     jun: 6,
//     jul: 7,
//     aug: 8,
//     sep: 9,
//     oct: 10,
//     nov: 11,
//     dec: 12,
//   };

//   const monthKey = monthName?.toLowerCase();

//   return monthMap[monthKey] || null;
// };

// function Gallery() {
//   const {
//     dispatch,
//     state: { showLoader },
//   } = useContext(GlobalContext);
//   const history = useHistory();
//   const location = useLocation();
//   const [showSnackBar, setShowSnackBar] = useState(false);
//   const [generateURLLoading, setGenerateURLLoading] = useState(false);

//   const queryParams = new URLSearchParams(location.search);
//   const year = queryParams.get("year");
//   const month = queryParams.get("month");
//   const day = queryParams.get("day");
//   const name = queryParams.get("name");
//   const user_id = queryParams.get("user_id");

//   const [snackbarContent, setSnackBarContent] = useState({
//     message: "",
//     class: "error",
//   });

//   const [galleryCopy, setGalleryCopy] = useState([]);
//   const [userCopy, setUserCopy] = useState([]);
//   const [searchTerm, setSearchTerm] = useState("");

//   const videoID = queryParams.get("video");
//   const selectedVideo = userCopy?.[0]?.videos?.filter(
//     (item) => item?.id === videoID
//   );
//   const {
//     name: video_name,
//     thumbnail,
//     approval,
//     id,
//   } = selectedVideo?.[0] || {};

//   const getAllVideo = async (value) => {
//     storeHandler(actions.SHOW_LOADER, true);

//     const determineFetchData = () => {
//       const monthNameInNum = monthNameToNumber(month);
//       if (year) {
//         if (month) {
//           if (day) {
//             if (name) {
//               return AuthServices.getUserGalleryVideo(
//                 year,
//                 monthNameInNum,
//                 day,
//                 user_id
//               );
//             }
//             return AuthServices.getGalleryDay(year, monthNameInNum, day);
//           }
//           return AuthServices.getGalleryDays(year, monthNameInNum);
//         }
//         return AuthServices.getGalleryMonth(year);
//       }
//       return AuthServices.getGalleryYear();
//     };

//     try {
//       const fetchData = determineFetchData();

//       const data = await fetchData;
//       if (day || name) {
//         setUserCopy(data?.data);
//         setGalleryCopy([]);
//       } else {
//         const updatedData = addKeyValueToNestedObjects(data, "type", value);
//         const desiredOrder = Object.keys(updatedData);
//         desiredOrder?.sort((a, b) => parseInt(a) - parseInt(b));
//         const sortedArray = desiredOrder?.map((key) => ({
//           key,
//           ...updatedData[key],
//         }));

//         setGalleryCopy(sortedArray || []);
//         setUserCopy([]);
//       }

//       storeHandler(actions.SHOW_LOADER, false);
//     } catch (error) {
//       console.log(error);
//       storeHandler(actions.SHOW_LOADER, false);
//     }
//   };

//   const handleNavigate = (params) => {
//     const query = new URLSearchParams(location.search);

//     params.forEach(({ key, value }) => {
//       query.set(key, value);
//     });

//     history.push({ search: query.toString() });
//   };

//   const handleGetGalleryVideo = () => {
//     if (year && !month && !day) {
//       getAllVideo("month");
//     } else if (year && month && !day) {
//       getAllVideo("day");
//     } else if (year && month && day && !name) {
//       getAllVideo();
//     } else if (year && month && day && name) {
//       getAllVideo();
//     } else {
//       getAllVideo("year");
//     }
//   };

//   useEffect(() => {
//     handleGetGalleryVideo();
//   }, [year, month, day, name]); 

//   const filteredVideos = useMemo(() => {
//     try {
//       if (!searchTerm.trim()) {
//         return userCopy;
//       }

//       const filteredUsers = userCopy.filter((user) =>
//         user.videos.some((video) =>
//           video.name.toLowerCase().includes(searchTerm.toLowerCase())
//         )
//       );

//       return filteredUsers.map((user) => ({
//         ...user,
//         videos: user.videos.filter((video) =>
//           video.name.toLowerCase().includes(searchTerm.toLowerCase())
//         ),
//       }));
//     } catch (err) {
//       console.log("An error occurred while filtering the data.");
//       return [];
//     }
//   }, [searchTerm, userCopy]);

//   //filter
//   const [filterAnchorEl, setFilterAnchorEl] = useState(null);

//   const handleFilterClose = () => {
//     setFilterAnchorEl(null);
//   };

//   const handleOpenFilterPopover = (event) => {
//     setFilterAnchorEl(event.currentTarget);
//   };

//   // const handleSearchChange = (value) => {
//   //   setSearchText(value);
//   // };

//   //filter

//   //Sort
//   const [sortAnchorEl, setSortAnchorEl] = useState(null);

//   const handleSortClose = () => {
//     setSortAnchorEl(null);
//   };

//   const handleOpenSortPopover = (event) => {
//     setSortAnchorEl(event.currentTarget);
//   };

//   //Sort

//   //implementaion

//   const [sortCriteria, setSortCriteria] = useState("");
//   const [filterCriteria, setFilterCriteria] = useState([]);
//   const [searchQuery, setSearchQuery] = useState("");

//   const handleSortChange = (criteria) => {
//     setSortCriteria(criteria);
//   };

//   const handleSearchChange = (query) => {
//     setSearchQuery(query);
//   };

//   const handleFilterChange = (filter) => {
//     setFilterCriteria((prev) => {
//       if (prev.includes(filter)) {
//         return prev?.filter((item) => item !== filter);
//       } else {
//         return [...prev, filter];
//       }
//     });
//   };

//   function roleFilteredUsers(data, inp, searchQuery) {
//     const approvalCriteria = inp.includes("approved");
//     const rejectionCriteria = inp.includes("rejected");

//     return data
//       ?.filter((user) => {
//         const matchesUserName = searchQuery
//           ? user.user_name?.toLowerCase().includes(searchQuery.toLowerCase())
//           : true;

//         return matchesUserName;
//       })
//       .map((user) => ({
//         ...user,
//         videos: user.videos.filter((video) => {
//           if (!approvalCriteria && !rejectionCriteria) {
//             return true;
//           }
//           return (
//             (approvalCriteria && video.approval === true) ||
//             (rejectionCriteria && video.approval === false)
//           );
//         }),
//       }));
//   }

//   function sortVideos(videos, sortCriteria) {
//     return videos?.sort((a, b) => {
//       const nameA = a.name.toLowerCase();
//       const nameB = b.name.toLowerCase();

//       if (sortCriteria === "dec") {
//         return nameB?.localeCompare(nameA);
//       } else if (sortCriteria === "acc") {
//         return nameA?.localeCompare(nameB);
//       } else if (sortCriteria === "accd") {
//         return new Date(a?.date) - new Date(b?.date);
//       } else if (sortCriteria === "decd") {
//         return new Date(b?.date) - new Date(a?.date);
//       }
//       return 0;
//     });
//   }

//   const sortedUsers = roleFilteredUsers(
//     filteredVideos,
//     filterCriteria,
//     searchQuery
//   ).map((user) => ({
//     ...user,
//     videos: sortVideos(user.videos, sortCriteria),
//   }));

//   const storeHandler = (type, payload) => dispatch({ type, payload });

//   // video

//   const [selectedVideoURL, setSelectedVideoURL] = useState("");

//   const generateVideoUrl = async () => {
//     if (!selectedVideo) {
//       console.log(selectedVideo?.[0], "selectedVideo?.[0] is empty");
//       return;
//     }
//     setGenerateURLLoading(true);
//     try {
//       const res_url = await simulatePostApi(selectedVideo?.[0]);
//       setSelectedVideoURL(res_url?.[0]);
//       setGenerateURLLoading(false);
//     } catch (error) {
//       console.log(error);
//       setGenerateURLLoading(false);
//     }
//   };

//   useEffect(() => {
//     setSelectedVideoURL(null);
//     generateVideoUrl();
//   }, [id]);

//   useEffect(() => {
//     if (day || name) {
//       setSearchQuery("");
//       setSortCriteria("");
//       setFilterCriteria([]);
//       setSearchTerm("");
//     }
//   }, [day, name]);

//   useEffect(() => {
//     if (selectedVideoURL) {
//       setSelectedVideoURL();
//       queryParams.delete("video");
//       const newUrl = `${location.pathname}?${queryParams.toString()}`;
//       history.replace(newUrl);
//     }
//   }, [searchQuery, searchTerm, filterCriteria]);

//   return (
//     <div className="pt-4">
//       <Paper
//         style={{
//           borderRadius: "1rem",
//           boxShadow:
//             "-10px -10px 50px 0px #FFF inset, 10px 10px 50px 0px rgba(211, 211, 211, 0.25) inset, -18px -18px 48.5px 0px #FFF, 18px 18px 50px 0px rgba(173, 173, 173, 0.29)",
//           border: "none",
//         }}
//         variant="outlined"
//         className={`${styles.GalleryHeader} d-flex justify-content-between align-items-center flex-md-row flex-column `}
//       >
//         <Typography
//           variant="h4"
//           style={{ fontSize: "2.3rem", fontWeight: "600" }}
//         >
//           Gallery
//         </Typography>
//       </Paper>
//       <Stack
//         direction="row"
//         className="pt-4 pb-3"
//         justifyContent="space-between"
//         alignItems="center"
//       >
//         <Stack direction="row" gap={"3rem"} alignItems="center">
//           <Breadcrumb />
//           {day && (
//             <InputField
//               value={searchTerm}
//               onChange={(event) => setSearchTerm(event.target.value)}
//               variant="filled"
//               placeholder="Search by video name"
//               withAdronment={true}
//             />
//           )}
//         </Stack>

//         {day && (
//           <Stack direction={"row"} gap={"1rem"} pr={".5rem"}>
//             <ButtonWithIcon
//               disableRipple
//               startIcon={<SortIcon />}
//               onClick={handleOpenSortPopover}
//             >
//               Sort
//             </ButtonWithIcon>
//             <GallerySortPopover
//               anchorEl={sortAnchorEl}
//               handleClose={handleSortClose}
//               handleSortChange={handleSortChange}
//               check={sortCriteria}
//             />

//             <ButtonWithIcon
//               disableRipple
//               startIcon={<FilterIcon />}
//               onClick={handleOpenFilterPopover}
//             >
//               Filter
//             </ButtonWithIcon>
//             <GalleryFilterPopover
//               anchorEl={filterAnchorEl}
//               handleClose={handleFilterClose}
//               handleFilterChange={handleFilterChange}
//               check={filterCriteria}
//               value={searchQuery}
//               onChange={handleSearchChange}
//               data={userCopy}
//             />
//           </Stack>
//         )}
//       </Stack>

//       <div
//         className={`${styles.galleryCardWrapper} py-4 d-flex flex-wrap justify-content-center justify-content-md-start`}
//       >
//         <>
//           {galleryCopy?.map((item) => (
//             <div className="px-3 my-0">
//               <YearCard key={item?.key} data={item} getData={handleNavigate} />
//             </div>
//           ))}

//           <Stack direction="row" width={"100%"}>
//             <Box style={{ width: "100%" }}>
//               {day &&
//                 sortedUsers?.map((item) => (
//                   <UserVideoList
//                     data={item}
//                     getData={handleNavigate}
//                     refresh={handleGetGalleryVideo}
//                   />
//                 ))}
//             </Box>
//             <Box>
//               {generateURLLoading && (
//                 <Stack
//                   width={'30rem'}
//                   height={'100%'}
//                   p={5}
//                   pt={'5rem'}
//                   gap={'.5rem'}
//                 >
//                   <Skeleton variant="rectangular" width={'100%'} height={'60%'} />
//                   <Skeleton height={'10%'}/>
//                   <Skeleton width="60%" height={'10%'} />
//                 </Stack>
//               )}
//               {selectedVideoURL && !generateURLLoading && (
//                 <div style={{ width: "30rem" }}>
//                   {" "}
//                   <div
//                     className="pr-3"
//                     style={{
//                       marginTop: "60px",
//                       marginLeft: "auto",
//                     }}
//                   >
//                     <div style={{ position: "relative" }} className="pt-4">
//                       <Typography
//                         variant="h5"
//                         style={{ position: "absolute", top: "-.6rem" }}
//                       >
//                         {video_name}
//                       </Typography>

//                       <VideoPlayer
//                         video={selectedVideoURL}
//                         thumbnail={thumbnail}
//                         approval={approval}
//                         id={id}
//                         name={video_name}
//                         refresh={handleGetGalleryVideo}
//                       />
//                     </div>
//                   </div>
//                 </div>
//               )}
//             </Box>
//           </Stack>
//         </>

//         {!showLoader && !galleryCopy?.length && !sortedUsers?.length && (
//           <p className="txt-xlg txt-primary flex-grow-1 text-center">
//             There are No Data!
//           </p>
//         )}
//       </div>

//       <Snackbar
//         open={showSnackBar}
//         message={snackbarContent?.message || ""}
//         className={snackbarContent?.class || ""}
//         autoHideDuration={4000}
//         closeSnackBar={setShowSnackBar}
//       />
//     </div>
//   );
// }

function Gallery () {

  return <MyGallery />
}

export default Gallery;
