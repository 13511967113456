import {
  Popover,
  Typography,
  List,
  ListItem,
  ListItemText,
  Box,
  Stack,
} from "@mui/material";
import { Checkbox } from "components";
import "./FreelancerFilterPopover.scss";


function FreelancerFilterPopover({
  anchorEl,
  handleClose,
  handleFilterChange,
  check,
}) {
  const role = ["approved", "rejected"];

  const handleSearchChange = (value) => {
    handleFilterChange(value);
  };

  return (
    <Box className="Download-popover-root">
      <Popover
        id={"freelancer-filter-popover"}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        slotProps={{
          paper: {
            style: {
              maxHeight: "20rem",
            },
          },
        }}
      >
        <div className="popover-content" style={{ minWidth: 180 }}>
          <List>
            {role.map((name) => (
              <ListItem style={{ cursor: "pointer" }}>
                <ListItemText
                  primary={
                    <>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        px={".8rem"}
                      >
                        <Typography
                          variant="body2"
                          textTransform={"capitalize"}
                        >
                          {name}
                        </Typography>
                        <Checkbox
                          size="small"
                          checked={check.includes(name)}
                          onChange={() => handleSearchChange(name)}
                        />
                      </Stack>
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        </div>
      </Popover>
    </Box>
  );
}

export default FreelancerFilterPopover;
