import React, { useContext } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import { ReactComponent as CurrentDownloadIcon } from "assets/images/videodownload.svg";
import { formatBytes } from "utils/videoUtils";
import { Typography } from "@mui/material";
import { useTheme } from "@material-ui/core";
import { GlobalContext } from "context";

const calculateStats = (progress, group_name, all_data) => {
  let totalSize = 0;
  let completedSize = 0;
  let allCompleted = true;
  let anyNotCompleted = false;
  let anyDownloadError = false;
  let anyNotStarted = false;

  all_data?.forEach((item) => {
    const memoryValue = Number(item.memory);
    if (!isNaN(memoryValue)) {
      totalSize += memoryValue;

      if (item.completed) {
        completedSize += memoryValue;
      } else {
        allCompleted = false;
        anyNotCompleted = true;
        if (!item.error) {
          anyNotStarted = true;
        }
      }
    }

    if (item.error) {
      anyDownloadError = true;
    }
  });

  if (progress && group_name === all_data?.[0]?.group_name) {
    completedSize += progress;
  }

  return {
    totalSize,
    completedSize,
    allCompleted,
    anyNotCompleted,
    anyDownloadError,
    anyNotStarted,
  };
};

const DownloadBox = ({ data }) => {
  const {
    downloaded_video,
    group_created_date,
    group_created_time,
    total_video,
    all_data,
  } = data || {};

  const {
    state: { download },
  } = useContext(GlobalContext);

  const { downloadQueueData, progress, size } = download || {};
  const { group_name, file_name } = downloadQueueData || {};

  const {
    totalSize,
    completedSize,
    allCompleted,
    anyNotCompleted,
    anyDownloadError,
    anyNotStarted,
  } = calculateStats(progress, group_name, all_data);
  let x;
  let video_count = null;
  if (allCompleted) {
    x = "Download Completed";
  } else if (group_name === all_data[0].group_name) {
    x = "Download In Progress";
  } else if (anyDownloadError) {
    x = "Error While Downloading";
  } else if (anyNotStarted) {
    x = "Download Not Started";
    video_count =
      all_data?.length > 1
        ? `${all_data[0]?.file_name} +${all_data?.length - 1}`
        : `${all_data[0]?.file_name}`;
  } else if (anyNotCompleted) {
    x = "Download Not Completed";
  }

  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  return (
    <Stack
      direction={"row"}
      borderBottom={"1px solid var(--text-disabled)"}
      sx={{
        background:
          group_name === all_data[0].group_name &&
          !allCompleted &&
          !isDark &&
          "#ffd8d8",
      }}
      justifyContent={"space-between"}
      alignItems={"center"}
      p={1.5}
      px={3}
      color={allCompleted && "gray"}
    >
      <Box position={"relative"} sx={{ width: "5%", minWidth: "5rem" }}>
        <CircularProgress
          variant="determinate"
          value={100}
          sx={{
            display: allCompleted && anyNotStarted ? "none" : "block",
            color: "lightgray",
            position: "absolute",
            top: "-3px",
            left: "-10px",
          }}
          size={"35px"}
        />
        <CircularProgress
          variant="determinate"
          value={(completedSize / totalSize) * 100}
          color="error"
          sx={{
            display: allCompleted && anyNotStarted ? "none" : "block",
            position: "absolute",
            top: "-3px",
            left: "-10px",
          }}
          size={"35px"}
        />

        <CurrentDownloadIcon
          width={"15px"}
          fill={allCompleted && anyNotStarted ? "lightgray" : "#C81E1E"}
          style={{ position: "relative", top: "3px" }}
        />
      </Box>
      <Box sx={{ width: "20%", minWidth: "15rem" }}>
        <Typography fontWeight={""} fontSize={".9rem"}>
          {x === "Download In Progress"
            ? file_name
            : x === "Download Not Started"
            ? `${video_count}`
            : x}
        </Typography>
      </Box>
      <Stack
        sx={{ width: "30%", minWidth: "25rem" }}
        direction={"row"}
        alignItems={"center"}
        gap={1}
      >
        <Box minWidth={"60%"} mr={1.5}>
          <LinearProgress
            sx={{ height: "3px" }}
            variant="determinate"
            color="error"
            value={
              x === "Download In Progress"
                ? (progress / size) * 100
                : x === "Download Completed"
                ? 100
                : 0
            }
          />
        </Box>
        <Box>
          <Typography fontWeight={""} fontSize={".8rem"}>
            {formatBytes(completedSize) || "0"}/{formatBytes(totalSize) || "-"}
          </Typography>
        </Box>
      </Stack>
      <Box sx={{ width: "10%", minWidth: "5rem" }}>
        <Typography fontWeight={""} fontSize={".8rem"}>
          {group_created_date}
        </Typography>
      </Box>
      <Box sx={{ width: "10%", minWidth: "5rem" }}>
        <Typography fontWeight={""} fontSize={".8rem"}>
          {group_created_time}
        </Typography>
      </Box>
      <Box sx={{ width: "10%", minWidth: "5rem" }}>
        <Typography fontWeight={""} fontSize={".8rem"}>
          {downloaded_video}/{total_video} Videos
        </Typography>
      </Box>
    </Stack>
  );
};

export default DownloadBox;
