import styles from "./prelogin.module.scss";
import { Box } from "components";
import { useLocation } from 'react-router-dom';

const PreLogIn = ({ children }) => {

  const location = useLocation();
  const isHelpPage = location.pathname === '/help'

  return (
    <Box
      className={`px-3 ${styles.loginWrapper} ${isHelpPage && styles.helpBG}`}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box className={`${styles.contentWrap}`}>{children}</Box>
    </Box>
  );
};

export default PreLogIn;
