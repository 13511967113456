import { Box, Stack } from "@mui/material";
import { ConfirmDialog, Snackbar } from "components";
import { useContext, useState } from "react";
import ButtonBox from "../ButtonBox";
import DownloadPopover from "../DownloadPopover/DownloadPopover";
import { ReactComponent as DownloadsIcon } from "assets/images/Downloads.svg";
import { ReactComponent as DeleteIcon } from "assets/images/delete.svg";
import { ReactComponent as FilterIcon } from "assets/images/filter.svg";
import RBGTable from "components/table/RBGTable";
import RBGPagination from "components/pagination/RBGPagination";
import { actions, GlobalContext } from "context";
import AuthServices from "api/services/auth-services";
import { getErrorMessage } from "utils/validator";

const AllVideo = ({
  getData,
  data,
  getAllVideo,
  page,
  setPage,
  count,
  handleContractorFilters,
  contractorCheck,
}) => {
  const { dispatch } = useContext(GlobalContext);
  const storeHandler = (type, payload) => dispatch({ type, payload });
  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState([]);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({
    message: "",
    class: "error",
  });

  const [openDialog, setOpenDialog] = useState({
    open: false,
    message: "",
    type: "",
  });

  const handleCloseDialog = () => {
    setOpenDialog({
      open: false,
      message: "",
      type: "",
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelected = (val) => {
    setSelected(val);
  };

  const handleAddVideo = (url) => {
    const currentDownloads =
      JSON.parse(localStorage.getItem("downloadQueue")) || [];
    currentDownloads.push(...url);
    localStorage.setItem("downloadQueue", JSON.stringify(currentDownloads));
    const event = new CustomEvent("localStorageChange", { key: Date.now() });
    window.dispatchEvent(event);
  };

  const downloadSelectedVideos = async () => {
    const groupName = `Group_${Date.now()}`;
    const filteredArr = data?.filter((obj) => selected?.includes(obj?.id));
    filteredArr.forEach((item) => {
      item.group_name = groupName;
      item.completed = false;
      item.error = false;
    });
    let GROUPS = JSON.parse(localStorage.getItem("video_group")) || [];
    GROUPS.push(...filteredArr);
    localStorage.setItem("video_group", JSON.stringify(GROUPS));
    handleAddVideo(filteredArr);

    setSelected([]);
    setOpenDialog({ message: "", open: false, type: "" });
  };

  const handleDownloadVideo = () => {
    downloadSelectedVideos();
  };

  const handleDeleteVideo = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    handleCloseDialog();
    try {
      const { message } = await AuthServices.deleteVideo({ id: selected });
      const data = {
        message: message || "Video successfully deleted",
        class: "delete",
      };
      setSelected([]);
      storeHandler(actions.SHOW_LOADER, false);
      setShowSnackBar(true);
      setSnackBarContent({ ...data });
    } catch (err) {
      storeHandler(actions.SHOW_LOADER, false);
      const MESSAGE = getErrorMessage(err);
      const data = {
        message: MESSAGE || "Something went worng!",
        class: "error",
      };
      setShowSnackBar(true);
      setSnackBarContent({ ...data });
    }
  };

  const handlePage = (val) => {
    setPage(val);
  };

  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <h3 className="txt-medium">All Storage Videos</h3>
        <Stack direction={"row"} gap={4}>
          {!!selected?.length && (
            <ButtonBox
              variant="contained"
              color="error"
              startIcon={<DownloadsIcon fill="white" />}
              onClick={() =>
                setOpenDialog({
                  open: true,
                  message: "Are you sure you want to Download?",
                  type: "download",
                })
              }
            >
              Download
            </ButtonBox>
          )}

          {!!selected?.length && (
            <ButtonBox
              variant="outlined"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() =>
                setOpenDialog({
                  open: true,
                  message: "Are you sure you want to Delete?",
                  type: "delete",
                })
              }
            >
              Delete
            </ButtonBox>
          )}

          {!selected?.length && (
            <ButtonBox
              aria-describedby={"download-popover"}
              variant="contained"
              color="inherit"
              startIcon={<FilterIcon fill="black" />}
              sx={{
                ":hover": {
                  backgroundColor: "#e0e0e0",
                },
              }}
              onClick={handleClick}
            >
              Filter
            </ButtonBox>
          )}
          <DownloadPopover
            anchorEl={anchorEl}
            handleClose={handleClose}
            handleContractorFilters={handleContractorFilters}
            contractorCheck={contractorCheck}
          />
        </Stack>
      </Stack>
      <Box>
        <RBGTable
          key={data?.length}
          page={0}
          selected={selected}
          setSelected={handleSelected}
          rows={data}
        />
        {!!data?.length && count > 1 && (
          <RBGPagination page={page} setPage={handlePage} count={count} />
        )}

        <ConfirmDialog
          onClose={handleCloseDialog}
          open={openDialog.open}
          message={openDialog.message}
          className={"download-conformbox"}
          onConfirm={
            openDialog.type === "download"
              ? handleDownloadVideo
              : handleDeleteVideo
          }
        />

        <Snackbar
          open={showSnackBar}
          message={snackbarContent?.message || ""}
          className={snackbarContent?.class || ""}
          autoHideDuration={4000}
          closeSnackBar={(val) => {
            setShowSnackBar(val);
            if (snackbarContent?.class !== "error") {
              getAllVideo(contractorCheck, 0);
            }
          }}
        />
      </Box>
    </>
  );
};

export default AllVideo;
