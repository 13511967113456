import React from "react";
import { useLocation, Link } from "react-router-dom";

// MUI Components
import { Breadcrumbs, Typography, Link as MuiLink } from "@material-ui/core";

// Icons
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const getMonthName = (monthAbbr) => {
  const monthMap = {
    JAN: "January",
    FEB: "February",
    MAR: "March",
    APR: "April",
    MAY: "May",
    JUN: "June",
    JUL: "July",
    AUG: "August",
    SEP: "September",
    OCT: "October",
    NOV: "November",
    DEC: "December",
  };
  const normalizedMonthAbbr = monthAbbr.toUpperCase();
  return monthMap[normalizedMonthAbbr] || "";
};

const Breadcrumb = ({user_name}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const year = queryParams.get("year");
  const month = queryParams.get("month");
  const day = queryParams.get("day");
  const name = user_name ||  queryParams.get("name");

  const breadcrumbItems = [
    { label: "Home", path: "/gallery" },
    ...(year ? [{ label: `${year}`, path: `/gallery?year=${year}` }] : []),
    ...(month
      ? [
          {
            label: `${getMonthName(month)}`,
            path: `/gallery?year=${year}&month=${month}`,
          },
        ]
      : []),
    ...(day
      ? [
          {
            label: `${day}`,
            path: `/gallery?year=${year}&month=${month}&day=${day}`,
          },
        ]
      : []),
    ...(name
      ? [
          {
            label: `${name}`,
            path: `/gallery?year=${year}&month=${month}&day=${day}&name=${name}`,
          },
        ]
      : []),
  ];

  return (
    <Breadcrumbs
      separator={
        <ArrowForwardIosIcon
          fontSize="small"
          style={{ width: "1rem", color: "var(--error-main)" }}
        />
      }
      aria-label="breadcrumb"
      style={{
        width: "max-content",
        padding: ".6rem 1rem",
        borderRadius: ".3rem",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
        textTransform: "capitalize",
      }}
    >
      {breadcrumbItems.map((item, index) =>
        index < breadcrumbItems.length - 1 ? (
          <MuiLink
            component={Link}
            to={item.path}
            color="textPrimary"
            key={index}
            style={{ textDecoration: "none" }}
          >
            {item.label}
          </MuiLink>
        ) : (
          <Typography color="inherit" key={index}>
            {item.label}
          </Typography>
        )
      )}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
