import { Box, Button, Stack } from "@mui/material";
import CurrentDownloadBox from "../CurrentDownloadBox";
import { useContext, useEffect, useState } from "react";
import { getDataFromLocalStorage } from "utils/videoUtils";
import { GlobalContext } from "context";

const GROUP = () => {
  const video_group = getDataFromLocalStorage("video_group") || [];
  const groupedData = video_group?.reduce((acc, item) => {
    const groupName = item.group_name;
    if (!acc[groupName]) {
      acc[groupName] = [];
    }
    acc[groupName].push(item);
    return acc;
  }, {});
  return groupedData;
};

const CurrentDownload = () => {
  const [data, setData] = useState([]);

  const {
    state: { download = {} },
  } = useContext(GlobalContext);

  const { downloadQueueData = {}, type, progress, size } = download;
  const { group_name, id } =
    downloadQueueData;

  useEffect(() => {
    const grouped = GROUP();
    setData(grouped?.[group_name] || []);
  }, [type]);

  const initialItemCount = 4;
  const [showAll, setShowAll] = useState(false);
  const visibleItems = showAll ? data?.length : initialItemCount;
  const itemsToShow = data?.slice(0, visibleItems);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  if (!data?.length) {
    return "";
  }

  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <h3 className="txt-medium">Current Downloads</h3>
      </Stack>
      <Stack direction={"row"} justifyContent={"space-between"} width={"95%"}>
        <Stack direction={"row"} gap={1} mb={3} flexWrap={"wrap"}>
          {itemsToShow.map((item) => (
            <CurrentDownloadBox
              key={item?.id}
              label={item?.file_name}
              min={item?.completed ? item?.memory : item?.error ?  0 : item?.id === id ? progress : '-' }
              max={item?.completed ? item?.memory : item?.id === id ? size : item?.memory}
            />
          ))}
        </Stack>
        {data?.length > initialItemCount && (
          <Box mt={1} mr={1}>
            <Button
              disableRipple
              variant="text"
              color="error"
              size="small"
              sx={{
                fontWeight: 600,
                fontSize: ".8rem",
                textDecorationLine: "underline",
                whiteSpace: "nowrap",
                ":hover": {
                  backgroundColor: "white",
                  textDecorationLine: "underline",
                },
              }}
              onClick={toggleShowAll}
            >
              {showAll ? " View Less" : "View All"}
            </Button>
          </Box>
        )}
      </Stack>
    </>
  );
};

export default CurrentDownload;
