import React, { useContext, useState } from "react";
import styles from "./InnerCommentStyle.module.scss";
import { ReactComponent as CloseIcon } from "../../../assets/images/comment-close.svg";
import { ReactComponent as ApproveIcon } from "assets/images/comment_approve.svg";
import { ReactComponent as RejectIcon } from "assets/images/comment_reject.svg";

import { Box, Button, Snackbar, TextField } from "components";
import {
  CircularProgress,
  InputAdornment,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Stack } from "@mui/material";
import { actions, GlobalContext } from "context";
import AuthServices from "api/services/auth-services";
import CommentsList from "../CommentList/CommentList";
import { getErrorMessage } from "utils/validator";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: ".5rem",
    },
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: ".5rem",
    },
  },
}));

const roundValue = (time) => {
  const validTime = Number(time);
  if (!isNaN(validTime) && validTime >= 0) {
    const roundedTime = Math.round(validTime);
    return roundedTime;
  } else {
    console.warn("Invalid time value provided:", time);
  }
};

function InnerComment({
  approval,
  id,
  comments,
  videRef,
  handleSetComment,
  isVideoPause,
  name,
  handleClose,
  getAllComment,
  refresh,
  updateVideoApproval,
}) {
  const {
    dispatch,
    state: { showLoader },
  } = useContext(GlobalContext);
  const classes = useStyles();
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({
    message: "",
    class: "error",
  });
  const [comment, setComment] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleApproveRejectVide = async (status) => {
    storeHandler(actions.SHOW_LOADER, true);

    try {
      await AuthServices.changeVideoStatus({ id, status });
      storeHandler(actions.SHOW_LOADER, false);
      videRef?.current?.pause();
      if (typeof updateVideoApproval === "function") {
        updateVideoApproval(id, status);
      } else if (typeof refresh === "function") {
        refresh();
      }
    } catch (error) {
      storeHandler(actions.SHOW_LOADER, false);
      const MESSAGE = getErrorMessage(error);
      setShowSnackBar(true);
      const content = {
        message: MESSAGE || "Please try again later",
        class: "error",
      };
      setSnackBarContent({ ...content });
    }
  };

  const handleAddComment = async () => {
    const currentTime = videRef?.current?.currentTime();
    if (comment.length === 0) {
      setErrorMessage("Comment cannot be empty.");
      return;
    } else if (comment.length > 300) {
      setErrorMessage("Comment cannot exceed 300 characters.");
      return;
    } else if (currentTime < 1) {
      setErrorMessage("Comment time should be greater than 0 seconds.");
      return;
    } else {
      setErrorMessage("");
    }
    storeHandler(actions.SHOW_LOADER, true);

    try {
      const payload = {
        video_id: id,
        time_sec: roundValue(currentTime),
        comment,
      };
      await AuthServices.addVideoComment(payload);
      setComment("");
      storeHandler(actions.SHOW_LOADER, false);
      getAllComment();
    } catch (error) {
      storeHandler(actions.SHOW_LOADER, false);
      setShowSnackBar(true);
      const content = {
        message: error?.data?.message || "Please try again later",
        class: "error",
      };
      setSnackBarContent({ ...content });
    }
  };

  const storeHandler = (type, payload) => dispatch({ type, payload });

  const seekToTime = (time) => {
    if (videRef.current) {
      videRef?.current?.currentTime(time);
      videRef?.current?.pause();
      console.log(`Seeked to ${time} seconds`);
    }
  };

  return (
    <div className={`${styles.commentBody}`}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
        position="relative"
      >
        <Typography variant="body1" style={{ fontWeight: "500" }}>
          {name}
        </Typography>
        <Stack direction="row" gap={".5rem"}>
          <Button
            startIcon={
              <ApproveIcon
                style={{
                  fill: "green",
                }}
              />
            }
            onClick={(e) => {
              handleApproveRejectVide(
                approval === null || approval === false ? true : null
              );
            }}
            style={{
              padding: ".4rem 1rem",
              borderRadius: "5px",
              fontSize: ".8rem",
              fontWeight: "200",
              backgroundColor: approval
                ? "rgba(0, 128, 0, 0.150)"
                : "rgba(128, 128, 128, 0.150)",
              color: approval ? "green" : "black",
              boxShadow: "none",
            }}
          >
            {approval === true ? "Unapprove" : "Approve"}
          </Button>

          <Button
            variant="contained"
            startIcon={
              <RejectIcon
                style={{
                  fill: "var(--error-main)",
                }}
              />
            }
            onClick={() =>
              handleApproveRejectVide(
                approval === null || approval === true ? false : null
              )
            }
            style={{
              padding: ".4rem 1rem",
              borderRadius: "5px",
              fontSize: ".8rem",
              fontWeight: "200",
              backgroundColor:
                approval === false ? "#d32f2f22" : "rgba(128, 128, 128, 0.150)",
              color: approval === false ? "var(--error-main)" : "black",
              boxShadow: "none",
            }}
          >
            {approval === false ? "Unreject" : "Reject"}
          </Button>
        </Stack>

        <Box style={{ position: "absolute", right: "-1.5rem", top: "-1.5rem" }}>
          <CloseIcon
            onClick={() => handleClose(false)}
            style={{
              width: "1.5rem",
              height: "1.5rem",
              cursor: "pointer",
              background: "var(--error-main)",
              borderRadius: "50%",
              padding: "3px",
            }}
          />
        </Box>
      </Stack>

      <Typography variant="body1" style={{ fontWeight: "500" }}>
        Comments
      </Typography>

      <Stack direction="column" style={{ overflowY: "scroll" }}>
        {!!comments?.length && (
          <CommentsList
            comments={comments}
            seekToTime={seekToTime}
            getAllComment={getAllComment}
          />
        )}
      </Stack>

      <div className="my-3">
        <TextField
          placeholder="Type your comment here"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          onFocus={() => videRef?.current?.pause()}
          classes={{ root: classes.root }}
          variant="outlined"
          fullWidth
          InputProps={{
            disableUnderline: true,
            style: {
              border: "none",
            },
            // startAdornment: (
            //   <InputAdornment position="start">
            //     {isVideoPause && (
            //       <Button
            //         variant="contained"
            //         disableRipple={true}
            //         style={{
            //           padding: ".3rem",
            //           fontSize: ".8rem",
            //           fontWeight: "200",
            //           backgroundColor: "white",
            //           color: "black",
            //           boxShadow: "none",
            //           cursor: "not-allowed",
            //         }}
            //       >
            //         {convertSecondsToHMS(
            //           videRef?.current?.currentTime(),
            //           false
            //         )}
            //       </Button>
            //     )}
            //   </InputAdornment>
            // ),
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  disabled={!isVideoPause}
                  onClick={handleAddComment}
                  variant="contained"
                  disableRipple={true}
                  style={{
                    minWidth: "max-content",
                    padding: "2px .5rem",
                    maxWidth: "max-content",
                    fontSize: ".8rem",
                    fontWeight: "200",
                    backgroundColor: "var(--error-main)",
                    color: "white",
                    boxShadow: "none",
                    borderRadius: ".4rem",
                  }}
                >
                  Post
                </Button>
              </InputAdornment>
            ),
          }}
          helperText={errorMessage}
        />
      </div>

      <Snackbar
        open={showSnackBar}
        message={snackbarContent?.message || ""}
        className={snackbarContent?.class || ""}
        autoHideDuration={4000}
        closeSnackBar={setShowSnackBar}
      />
      {!!showLoader && (
        <Box className={styles.loader_box}>
          <div className={styles.loader}>
            <CircularProgress />
          </div>
        </Box>
      )}
    </div>
  );
}

export default InnerComment;
