import { PreLogIn, PostLogIn } from "layout";
import { PrivateRoute, PublicRoute } from "routes";
import { useContext, useEffect } from "react";
import { Switch, useHistory } from "react-router-dom";
import { GlobalContext, actions } from "context";

import Login from "./login/login";
import ForgotPassword from "./forget-passsword/forget-password";
import Dashboard from "app/dashboard/dashboard";
import FreeLancer from "app/freelancer/freelancer";
import Admin from "app/admin/admin";
import FreeLancerInfo from "app/freelancer-info/freelancer-info";
import CircularProgress from "@material-ui/core/CircularProgress";
import Gallery from "./Gallery/Gallery";
import videoPlayer from "./video-player";
import Help from "./help/help";
import Downloads from "./Downloads/Downloads";
import { getErrorMessage } from "utils/validator";
import AuthServices from "api/services/auth-services";

function App() {
  const history = useHistory();
  const {
    state: { showLoader },
    dispatch,
  } = useContext(GlobalContext);

  const getAuthToken = async () => {
    try {
      const { access_token } = await AuthServices.refreshToken();
      localStorage.setItem("access_token", access_token);
      storeHandler(actions.LOG_IN, {
        isLoggedIn: true,
        isAdmin: null,
      });
    } catch (error) {
      getErrorMessage(error);
      localStorage.clear();
      history.push("/");
    }
  };

  const handleLogout = async () => {
    try {
      localStorage.clear();
    } catch (error) {
      getErrorMessage(error);
    }
  };

  const validateLogIn = () => {
    try {
      const access_token = localStorage.getItem("access_token");
      const refresh_token = localStorage.getItem("refresh_token");

      if (!access_token && !refresh_token) {
        handleLogout();
        return;
      } else if (!access_token && refresh_token) {
        getAuthToken();
      } else {
        const isLoggedIn = !!access_token;
        storeHandler(actions.LOG_IN, {
          isLoggedIn,
          isAdmin: null,
        });
      }
    } catch (error) {
      console.error("Error validating login:", error);
      handleLogout();
    }
  };

  useEffect(() => {
    validateLogIn();
  }, []);

  const storeHandler = (type, payload) => dispatch({ type, payload });

  return (
    <div>
      {!!showLoader && (
        <div className="loader" style={{ zIndex: 9999999 }}>
          <CircularProgress />
        </div>
      )}
      <Switch>
        <PublicRoute path="/login" component={Login} layout={PreLogIn} />
        <PublicRoute path="/help" component={Help} layout={PreLogIn} />
        <PublicRoute
          path="/forgot-password"
          component={ForgotPassword}
          layout={PreLogIn}
        />
        <PrivateRoute
          path="/contractors"
          component={FreeLancer}
          layout={PostLogIn}
        />
        <PrivateRoute path="/users" component={Admin} layout={PostLogIn} />
        <PrivateRoute
          path="/user-info"
          component={FreeLancerInfo}
          layout={PostLogIn}
        />
        <PrivateRoute
          path="/video"
          component={videoPlayer}
          layout={PostLogIn}
        />
        <PrivateRoute
          path="/video-download"
          component={Downloads}
          layout={PostLogIn}
        />
        <PrivateRoute path="/gallery" component={Gallery} layout={PostLogIn} />
        <PrivateRoute path="/" component={Dashboard} layout={PostLogIn} />
      </Switch>
    </div>
  );
}

export default App;
