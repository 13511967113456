import { useState, useContext, useEffect } from "react";
import styles from "../admin.module.scss";
import avatar from "assets/images/default.svg";
import {
  Button,
  InputField,
  TextField,
  Snackbar,
  DatePicker,
  Box,
} from "components";
import AuthServices from "api/services/auth-services";
import { ReactComponent as UploadIcon } from "assets/images/upload.svg";
import CloseIcon from "@mui/icons-material/Close";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { GlobalContext, actions } from "context";
import {
  getErrorMessage,
  isEmailValid,
  trimObjectValues,
} from "utils/validator";
import { Autocomplete, Stack } from "@mui/material";
import ButtonWithIcon from "components/IconButton/icon-button";
import { Paper, styled, Typography, TextField as TF } from "@material-ui/core";
import { convertImageToBase64WithMetadata } from "utils/videoUtils";
import { INPUT_LIMITS } from "utils/constant";
import moment from 'moment-timezone';

const adminfieldErrors = {
  name: "Enter Name",
  email: "Enter Email",
  phone: "Enter phone number",
  date_joining: "Enter a valid date of joining",
  country: "Select Country",
  state: "Select State",
};

const AdminAutoCompleteTextField = styled(TF)({
  "& .MuiOutlinedInput-root": {
    padding: ".25rem .3rem !important",
    borderBottom: "1.5px solid #D0D0D0",
    borderTop: "none",
    borderRight: "none",
    borderLeft: "none",
    borderRadius: "0",
    fontSize: ".9rem",
    "& fieldset": {
      border: "none",
    },
  },
});

const AdminStyledPaper = styled(Paper)({
  boxShadow: "none !important",
  fontSize: "0.9rem",
  "& .MuiAutocomplete-listbox": {
    padding: 0,
    "& .MuiAutocomplete-option": {
      marginTop: "2px",
      backgroundColor: "white",
      border: "1px solid #c6c6c6",
      fontSize: "13px",
      textTransform: "capitalize",
    },
  },
});

const EditAdmin = ({ handleClose, open, info, getData }) => {
  const [newAdmin, setNewAdmin] = useState({});
  const [imageFile, setImageFile] = useState();
  const [image, setImage] = useState();
  const [isInvalidForm, setIsInvalidForm] = useState(false);
  const {
    dispatch,
    state: { profile, country_state },
  } = useContext(GlobalContext);

  const COUNTRY_LIST = Object.keys(country_state);
  const STATE_LIST = country_state[newAdmin?.country || ""] || [];

  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({
    message: "",
    class: "error",
  });
  const [invalidFileSize, setInvalidFileSize] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [minLengthInvalid, setMinLengthInvalid] = useState(false);

  const [phoneLenInvalid, setPhoneLenInvalid] = useState(null);
  const [altPhoneLenInvalid, setAltPhoneLenInvalid] = useState(null);

  const validate = () => {
    const trimedValue = trimObjectValues(newAdmin);
    let isInvalid = false;
    const inputFields = Object.keys(adminfieldErrors);
    inputFields.forEach((item) => {
      if (!trimedValue[item]) {
        isInvalid = true;
        setIsInvalidForm(true);
        setNewAdmin(trimedValue);
        return;
      }

      if (item === "date_joining") {
        const dob = moment(trimedValue?.dob);
        const doj = moment(trimedValue?.date_joining);

        if (doj.isValid() && trimedValue?.dob) {
          if (dob.isSameOrAfter(doj, "day")) {
            isInvalid = true;
            setIsInvalidForm(true);
            editFreeLancer("date_joining", null);
            adminfieldErrors.date_joining =
              "The date of joining must be later than the date of birth.";
            return;
          }
        }
      }
    });
    if (!isInvalid) {
      const { email } = newAdmin;

      const isValidEmail = isEmailValid(email);
      setInvalidEmail(!isValidEmail);

      if (
        !isValidEmail ||
        minLengthInvalid ||
        altPhoneLenInvalid ||
        phoneLenInvalid
      ) {
        setIsInvalidForm(true);
      } else {
        setIsInvalidForm(false);
        updateFreelancer();
        setInvalidEmail(false);
      }
    }
  };

  const editFreeLancer = (key, value) => {
    const val = value;
    const data = {
      ...newAdmin,
      [key]: key === "email" ? val.toLowerCase() : val,
    };
    setNewAdmin(data);
  };

  const updateFreelancer = async () => {
    const base64Image = imageFile
      ? await convertImageToBase64WithMetadata(imageFile)
      : null;
    const payload = {
      ...newAdmin,
      profile: base64Image?.base64,
      content_type: base64Image?.content_type,
      filename: base64Image?.filename,
      ...(newAdmin.dob && { dob: dateFormat(newAdmin.dob) }),
      ...(newAdmin.date_joining && {
        date_joining: dateFormat(newAdmin.date_joining),
      }),
    };
    const isCurrentProfile = payload.account_id === profile?.account_id;

    storeHandler(actions.SHOW_LOADER, true);

    try {
      const trimedValue = trimObjectValues(payload);
      const { message } = await AuthServices.editUser(trimedValue);
      storeHandler(actions.SHOW_LOADER, false);
      storeHandler(actions.REFRESH_PROFILE, isCurrentProfile);

      setShowSnackBar(true);
      const content = {
        message: message,
        class: "success",
      };
      setSnackBarContent({ ...content });
    } catch (err) {
      storeHandler(actions.SHOW_LOADER, false);
      const MESSAGE = getErrorMessage(err);
      setShowSnackBar(true);
      const content = {
        message: MESSAGE,
        class: "error",
      };
      setSnackBarContent({ ...content });
    }
  };

  const dateFormat = (val) => {
    const [month, date, year] = new Date(val).toLocaleDateString().split("/");
    return `${year}/${month}/${date}`;
  };

  const addImg = (event) => {
    if (
      !event.target ||
      !event.target.files ||
      event.target.files.length === 0
    ) {
      console.error("No file selected or invalid event target");
      return;
    }

    const img = event.target.files[0];

    if (!["image/jpeg", "image/jpg", "image/png"].includes(img.type)) {
      console.error("Selected file is not a JPEG or JPG image");
      setInvalidFileSize(true);
      return;
    }

    // Validate file size
    const fileSizeMB = img.size / 1024 / 1024;
    const isValidFileSize = fileSizeMB <= 2;

    if (isValidFileSize) {
      setImage(URL.createObjectURL(img));
      setImageFile(img);
      setInvalidFileSize(false);
      editFreeLancer("delete_key", false);
    } else {
      setInvalidFileSize(true);
    }
  };

  const removeImg = () => {
    setImage(null);
    setImageFile(null);
    setInvalidFileSize(false);
    editFreeLancer("delete_key", true);
  };

  const emailValidator = (event) => {
    const value = event.target.value;
    setInvalidEmail(!isEmailValid(value));
  };

  const textValidator = (event) => {
    const value = event.target.value;
    const invalid = value.length < 3 || value.length > 30;
    setMinLengthInvalid(invalid);
  };

  const phoneValidator = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    const invalid = value.length < 10 || value.length > 15;

    if (name === "phone") {
      if (value) {
        setPhoneLenInvalid(invalid);
      } else {
        setPhoneLenInvalid(null);
      }
    }

    if (name === "alternate_phone") {
      if (value) {
        setAltPhoneLenInvalid(invalid);
      } else {
        setAltPhoneLenInvalid(null);
      }
    }
  };

  useEffect(() => {
    if (info) {
      setNewAdmin({ ...info });
      setImage(info.profile);
    }
  }, []);

  const storeHandler = (type, payload) => dispatch({ type, payload });

  return (
    <Dialog
      PaperProps={{
        style: {
          borderRadius: "1rem",
        },
      }}
      maxWidth={"lg"}
      open={open}
      onClose={handleClose}
      fullWidth={true}
    >
      <DialogContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mt={".5rem"}
          mb={"1.5rem"}
        >
          <Stack direction="row" alignItems="center" gap="1rem">
            <p
              className="txt-primary txt-xlg m-0 px-md-3"
              style={{ fontWeight: "500" }}
            >
              Edit User
            </p>
            <Box style={{ position: "relative", top: ".3rem" }}>
              <Typography variant="subtitle1" style={{ fontWeight: "500" }}>
                Role:{" "}
                <span style={{ color: "var(--error-main)" }}>
                  {newAdmin["qa_user"] ? "QA" : "Admin"}
                </span>
              </Typography>
            </Box>
          </Stack>
          <ButtonWithIcon
            startIcon={<CloseIcon />}
            style={{ padding: ".5rem 1rem" }}
            onClick={handleClose}
          >
            Close
          </ButtonWithIcon>
        </Stack>
        <div className="row pb-4">
          <div className="d-flex flex-wrap  px-0 px-md-3 col-md-8 col-12">
            <div className="col-sm-6 col-12 mb-3">
              <label className="txt-bold txt-md required-label">Name</label>
              <TextField
                value={newAdmin?.name || ""}
                variant="filled"
                className={"mr-3"}
                alphanumeric={true}
                fullWidth
                placeholder="Full Name"
                onBlur={textValidator}
                onChange={(event) => {
                  const name = event.target.value;
                  if (name.length <= INPUT_LIMITS.NAME) {
                    editFreeLancer("name", name);
                  }
                }}
                helperText={
                  (isInvalidForm &&
                    !newAdmin["name"] &&
                    adminfieldErrors["name"]) ||
                  (minLengthInvalid &&
                    (newAdmin["name"]?.length > 30
                      ? "Enter less than 30 characters"
                      : "Enter minimum 3 characters"))
                }
              />
            </div>
            <div className="col-sm-6 col-12 mb-3">
              <label className="txt-bold txt-md required-label">Email</label>
              <InputField
                value={newAdmin?.email || ""}
                variant="filled"
                className={"mr-3"}
                fullWidth
                placeholder="Email Id"
                onBlur={emailValidator}
                onChange={(event) => {
                  const email = event.target.value;
                  if (email.length <= INPUT_LIMITS.EMAIL) {
                    editFreeLancer("email", email);
                  }
                }}
                helperText={
                  (isInvalidForm &&
                    !newAdmin["email"] &&
                    adminfieldErrors["email"]) ||
                  (invalidEmail && "Enter valid email id")
                }
              />
            </div>
            <div className="col-sm-6 col-12 mb-3">
              <label className="txt-bold txt-md required-label">Country</label>
              <Autocomplete
                key={newAdmin["country"]}
                disableClearable
                value={COUNTRY_LIST.find(
                  (option) => option === newAdmin["country"]
                )}
                onChange={(_, value) => {
                  editFreeLancer("country", value);
                }}
                options={COUNTRY_LIST}
                renderInput={(params) => (
                  <AdminAutoCompleteTextField
                    {...params}
                    size="small"
                    variant="outlined"
                    placeholder="Select Country"
                    helperText={
                      isInvalidForm &&
                      !newAdmin["country"] &&
                      adminfieldErrors["country"]
                    }
                  />
                )}
                componentsProps={{
                  paper: {
                    component: AdminStyledPaper,
                  },
                }}
              />
            </div>
            <div className="col-sm-6 col-12 mb-3">
              <label className="txt-bold txt-md required-label">State</label>
              <Autocomplete
                key={newAdmin["state"]}
                disableClearable
                value={STATE_LIST?.find(
                  (option) => option === newAdmin["state"]
                )}
                onChange={(_, value) => {
                  editFreeLancer("state", value);
                }}
                options={STATE_LIST}
                renderInput={(params) => (
                  <AdminAutoCompleteTextField
                    {...params}
                    size="small"
                    variant="outlined"
                    placeholder="Select State"
                    helperText={
                      isInvalidForm &&
                      !newAdmin["state"] &&
                      adminfieldErrors["state"]
                    }
                  />
                )}
                componentsProps={{
                  paper: {
                    component: AdminStyledPaper,
                  },
                }}
              />
            </div>
            <div className="col-sm-6 col-12 mb-3">
              <label className="txt-bold txt-md">Address</label>
              <InputField
                value={newAdmin?.address || ""}
                variant="filled"
                className={"mr-3"}
                fullWidth
                placeholder="Add Address"
                onChange={(event) => {
                  const address = event.target.value;
                  if (address.length <= INPUT_LIMITS.ADDRESS) {
                    editFreeLancer("address", address);
                  }
                }}
                helperText={
                  isInvalidForm &&
                  !newAdmin["address"] &&
                  adminfieldErrors["address"]
                }
              />
            </div>

            <div className="col-sm-6 col-12 mb-3">
              <label className="txt-bold txt-md">ZIP</label>
              <TextField
                value={newAdmin?.zipcode || ""}
                variant="filled"
                className={"mr-3"}
                type="number"
                fullWidth
                placeholder="City zip code"
                onChange={(event) => {
                  const zipcode = event.target.value;
                  if (zipcode.length <= INPUT_LIMITS.ZIP) {
                    editFreeLancer("zipcode", zipcode);
                  }
                }}
                helperText={
                  isInvalidForm &&
                  !newAdmin["zipcode"] &&
                  adminfieldErrors["zipcode"]
                }
              />
            </div>
            <div className="col-sm-6 col-12 mb-3">
              <label className="txt-bold txt-md required-label">Phone</label>
              <TextField
                value={newAdmin?.phone || ""}
                variant="filled"
                className={"mr-3"}
                type="number"
                name="phone"
                onBlur={phoneValidator}
                fullWidth
                placeholder="Mobile Number"
                onChange={(event) => {
                  const phone = event.target.value;
                  if (phone.length <= INPUT_LIMITS.PHONE) {
                    editFreeLancer("phone", phone);
                  }
                }}
                helperText={
                  (isInvalidForm &&
                    !newAdmin["phone"] &&
                    adminfieldErrors["phone"]) ||
                  (phoneLenInvalid === true &&
                    "Phone Number must be between 10 and 15 digits.")
                }
              />
            </div>

            <div className="col-sm-6 col-12 mb-3">
              <label className="txt-bold txt-md">Alternate Phone</label>
              <TextField
                value={newAdmin?.alternate_phone || ""}
                variant="filled"
                className={"mr-3"}
                type="number"
                fullWidth
                onBlur={phoneValidator}
                name="alternate_phone"
                placeholder="Alternate Mobile Number"
                onChange={(event) => {
                  const alternate_phone = event.target.value;
                  if (alternate_phone.length <= INPUT_LIMITS.PHONE) {
                    editFreeLancer("alternate_phone", alternate_phone);
                  }
                }}
                helperText={
                  altPhoneLenInvalid === true &&
                  "Phone Number must be between 10 and 15 digits."
                }
              />
            </div>
            <div className="col-sm-6 col-12 mb-3">
              <label className="txt-bold txt-md">Date of Birth</label>
              <DatePicker
                className="mt-0"
                value={newAdmin?.dob || null}
                onChange={(val) => editFreeLancer("dob", val)}
                helperText={
                  isInvalidForm && !newAdmin["dob"] && adminfieldErrors["dob"]
                }
              />
            </div>

            <div className="col-sm-6 col-12 mb-3">
              <label className="txt-bold txt-md required-label">
                Date of Joining
              </label>
              <DatePicker
                className="mt-0"
                value={newAdmin?.date_joining || null}
                onChange={(val) => editFreeLancer("date_joining", val)}
                helperText={
                  isInvalidForm &&
                  !newAdmin["date_joining"] &&
                  adminfieldErrors["date_joining"]
                }
              />
            </div>
          </div>
          <div className="align-items-center col-12 col-md-4 d-flex flex-column flex-wrap">
            <div className={`d-flex flex-column ${styles.addImg}`}>
              <div className="text-center">
                {" "}
                <img
                  src={image ? image : avatar}
                  alt=""
                  className={styles.upload}
                />
              </div>

              {!image && (
                <ButtonWithIcon startIcon={<UploadIcon />}>
                  Upload Image
                  <TextField
                    className={styles.uploadImg}
                    inputProps={{
                      type: "file",
                      accept: "image/*",
                      id: "uploadImg",
                    }}
                    onChange={addImg}
                  />
                </ButtonWithIcon>
              )}

              {image && (
                <ButtonWithIcon onClick={removeImg} startIcon={<CloseIcon />}>
                  Remove Image
                </ButtonWithIcon>
              )}
            </div>
            <Typography
              variant="caption"
              style={{ color: "var(--text-disabled)" }}
            >
              Image size should be 2MB Max
            </Typography>

            {invalidFileSize && (
              <Typography
                variant="caption"
                style={{ color: "var(--error-main)" }}
              >
                Upload a .jpg or .jpeg image up to 2 MB.
              </Typography>
            )}
          </div>
          <div className="col-12 text-center">
            <Button
              variant="contained"
              color="primary"
              className="mt-3"
              onClick={validate}
              style={{
                padding: ".5rem 1.5rem",
                background:
                  "linear-gradient(100deg, var(--error-main) 0%, rgba(255,135,135,1) 55%, var(--error-main) 100%)",
              }}
            >
              Save
            </Button>
          </div>
        </div>
        <Snackbar
          open={showSnackBar}
          message={snackbarContent?.message || ""}
          className={snackbarContent?.class || ""}
          autoHideDuration={4000}
          closeSnackBar={(val) => {
            setShowSnackBar(val);
            if (snackbarContent?.class !== "error") {
              handleClose();
              getData();
            }
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EditAdmin;
