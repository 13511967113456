import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Box,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import "./CommentListPopover.scss";
import { ReactComponent as Options } from "assets/images/comment_options.svg";

const role = ["edit", "remove"];

function CommentListPopover({
  editComment,
  deleteComment,
  expanded,
  handleChange,
  id,
}) {
  const handleEvent = (event) => {
    event?.stopPropagation();
  };

  return (
    <Box className="Download-popover-root" onClick={handleEvent}>
      <div style={{ position: "absolute", right: "0", top: ".5rem" }}>
        <Accordion expanded={expanded === id} onChange={handleChange(id)} sx={{boxShadow:'none'}}>
          <AccordionSummary
            aria-controls="panel1d-content"
            sx={{
              padding:0,
              "&.MuiAccordionSummary-root": { minHeight: "0", maxHeight:'1rem' },
            }}
          >
            <Options style={{margin:'auto'}}/>
          </AccordionSummary>
          <AccordionDetails sx={{ m: 0, p: 0 }}>
            <List style={{ border: "1px solid gray", borderRadius: "2px", padding:0 }}>
              {role.map((name) => (
                <ListItem
                  style={{ cursor: "pointer", borderBottom: "1px solid gray", padding:0 }}
                  sx={{
                    ":hover": {
                      background: "gray !important",
                      color: "white",
                    },
                  }}
                >
                  <ListItemText
                    primary={
                      <>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          px={".8rem"}
                          onClick={() => {
                            if (name === "edit") {
                              editComment();
                            } else {
                              deleteComment();
                            }
                          }}
                        >
                          <Typography
                            variant="body2"
                            textTransform={"capitalize"}
                            fontSize='.8rem'
                          >
                            {name}
                          </Typography>
                        </Stack>
                      </>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      </div>
    </Box>
  );
}

export default CommentListPopover;
