import { useState, useContext, useEffect, useMemo } from "react";
import styles from "./UserCard.module.scss";
import avatar from "assets/images/default.svg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { ReactComponent as SortIcon } from "assets/images/sort.svg";
import { ReactComponent as FilterIcon } from "assets/images/filter.svg";

import { Box, Stack, Typography } from "@mui/material";

import NoVideo from "assets/images/video-placeholder.svg";
import { GlobalContext, actions } from "context";

import { useLocation } from "react-router-dom";
import VideoCard from "app/freelancer-info/video-card/video-card";
import { simulatePostApi } from "app/downloadfn";
import VideoPlayer from "components/custom-video-player/VideoPlayer";
import Breadcrumb from "../breadcrumb/breadcrumb";
import { InputField } from "components";
import ButtonWithIcon from "components/IconButton/icon-button";
import GallerySortPopover from "app/Gallery/gallery-sort-popover/GallerySortPopover";
import GalleryFilterPopover from "app/Gallery/gallery-filter-opover/GalleryFilterPopover";

function roleFilteredUsers(data, inp, searchQuery) {
  const approvalCriteria = inp.includes("approved");
  const rejectionCriteria = inp.includes("rejected");

  return data
    ?.filter((user) => {
      const matchesUserName = searchQuery
        ? user.user_name?.toLowerCase().includes(searchQuery.toLowerCase())
        : true;

      return matchesUserName;
    })
    .map((user) => ({
      ...user,
      videos: user.videos.filter((video) => {
        if (!approvalCriteria && !rejectionCriteria) {
          return true;
        }
        return (
          (approvalCriteria && video.approval === true) ||
          (rejectionCriteria && video.approval === false)
        );
      }),
    }));
}

function sortVideos(videos, sortCriteria) {
  return videos?.sort((a, b) => {
    const nameA = a.file_name.toLowerCase();
    const nameB = b.file_name.toLowerCase();

    if (sortCriteria === "dec") {
      return nameB?.localeCompare(nameA);
    } else if (sortCriteria === "acc") {
      return nameA?.localeCompare(nameB);
    } else if (sortCriteria === "accd") {
      return new Date(a?.date) - new Date(b?.date);
    } else if (sortCriteria === "decd") {
      return new Date(b?.date) - new Date(a?.date);
    }
    return 0;
  });
}

function sortVideosByName(data, sortCriteria) {
  return data?.sort((a, b) => {
    const nameA = a.user_name.toLowerCase();
    const nameB = b.user_name.toLowerCase();

    if (sortCriteria === "dec") {
      return nameB?.localeCompare(nameA);
    } else if (sortCriteria === "acc") {
      return nameA?.localeCompare(nameB);
    }
    return 0;
  });
}

const UserCard = ({
  data,
  getData,
  showVideo,
  cleanVideoURL,
  refresh,
  updateVideoApproval,
}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const day = queryParams.get("day");
  const userID = queryParams.get("user_id");
  const videoID = queryParams.get("video_id");

  //   const selectedVideo = data?.videos?.filter((item) => item?.id === videoID);
  const selectedVideo = data
    ?.reduce((acc, item) => {
      return acc.concat(item.videos);
    }, [])
    .filter((video) => video.id === videoID);
  const { file_name, thumbnail, id, approval } = selectedVideo?.[0] || {};
  const {
    dispatch,
    state: { showLoader },
  } = useContext(GlobalContext);

  const [selectedVideos, setSelectedVideos] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isBulkSelect, setIsBulkSelect] = useState(false);

  const [unselectAll, setUnSelectAll] = useState(false);

  const [videoURL, setVideoURL] = useState("");

  const generateVideoUrl = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const res_url = await simulatePostApi({ id: videoID });
      setVideoURL(res_url?.[0]);
      storeHandler(actions.SHOW_LOADER, false);
    } catch (error) {
      console.log(error);
      storeHandler(actions.SHOW_LOADER, false);
    }
  };

  useEffect(() => {
    if (showVideo && videoID) {
      generateVideoUrl();
    }
  }, [videoID, showVideo]);

  useEffect(() => {
    return () => {
      typeof cleanVideoURL === "function" && cleanVideoURL();
    };
  }, []);

  const storeHandler = (type, payload) => dispatch({ type, payload });

  const [searchTerm, setSearchTerm] = useState("");
  const [sortCriteria, setSortCriteria] = useState("");
  const [filterCriteria, setFilterCriteria] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const areFiltersEmpty = () => {
    return (
      !searchTerm &&
      !sortCriteria &&
      filterCriteria?.length === 0 &&
      !searchQuery
    );
  };

  const handleSortChange = (criteria) => {
    setSortCriteria((pre) => (pre === criteria ? "" : criteria));
  };

  const handleSearchChange = (query) => {
    setSearchQuery(query);
  };

  const filteredVideos = useMemo(() => {
    try {
      if (!searchTerm.trim()) {
        return data;
      }

      const filteredUsers = data.filter((user) =>
        user.videos.some((video) =>
          video.file_name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );

      return filteredUsers.map((user) => ({
        ...user,
        videos: user.videos.filter((video) =>
          video.file_name.toLowerCase().includes(searchTerm.toLowerCase())
        ),
      }));
    } catch (err) {
      console.log("An error occurred while filtering the data.");
      return [];
    }
  }, [searchTerm, data]);

  const sortedUsersList = roleFilteredUsers(
    filteredVideos,
    filterCriteria,
    searchQuery
  );

  const sortedUsers = userID
    ? sortedUsersList.map((user) => ({
        ...user,
        videos: sortVideos(user.videos, sortCriteria),
      }))
    : sortVideosByName(sortedUsersList, sortCriteria);

  const [sortAnchorEl, setSortAnchorEl] = useState(null);

  const handleSortClose = () => {
    setSortAnchorEl(null);
  };

  const handleOpenSortPopover = (event) => {
    setSortAnchorEl(event.currentTarget);
  };

  const [filterAnchorEl, setFilterAnchorEl] = useState(null);

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleOpenFilterPopover = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterChange = (filter) => {
    setFilterCriteria((prev) => {
      if (prev.includes(filter)) {
        return prev?.filter((item) => item !== filter);
      } else {
        return [...prev, filter];
      }
    });
  };

  const getUserName = () => {
    const user = sortedUsers?.[0];
    if (userID && user?.user_name) {
      return user?.user_name;
    }
    return "";
  };

  useEffect(() => {
    setSearchTerm("");
    setSearchQuery("");
    setSortCriteria("");
    setFilterCriteria([]);
  }, [data]);
  return (
    <>
      <Stack
        direction="row"
        className="pt-4 pb-3"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack direction="row" gap={"3rem"} alignItems="center">
          <Breadcrumb user_name={getUserName()} />
          {day && (
            <InputField
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.target.value)}
              variant="filled"
              placeholder="Search by video name"
              withAdronment={true}
            />
          )}
        </Stack>

        {day && (
          <Stack direction={"row"} gap={"1rem"} pr={".5rem"}>
            <ButtonWithIcon
              disableRipple
              startIcon={<SortIcon />}
              onClick={handleOpenSortPopover}
            >
              Sort
            </ButtonWithIcon>
            <GallerySortPopover
              anchorEl={sortAnchorEl}
              handleClose={handleSortClose}
              handleSortChange={handleSortChange}
              check={sortCriteria}
            />

            <ButtonWithIcon
              disableRipple
              startIcon={<FilterIcon />}
              onClick={handleOpenFilterPopover}
            >
              Filter
            </ButtonWithIcon>
            <GalleryFilterPopover
              anchorEl={filterAnchorEl}
              handleClose={handleFilterClose}
              handleFilterChange={handleFilterChange}
              check={filterCriteria}
              value={searchQuery}
              onChange={handleSearchChange}
              data={data}
            />
          </Stack>
        )}
      </Stack>
      <Stack direction="row">
        <Box style={{ width: "100%" }}>
          {day &&
            sortedUsers?.map(({ user_name, user_id, profile, videos }) => (
              <>
                <Stack
                  direction="row"
                  gap={"1rem"}
                  alignItems="center"
                  style={{ cursor: "pointer", textTransform: "capitalize" }}
                  onClick={() => getData([{ key: "user_id", value: user_id }])}
                  pt={3}
                >
                  <img
                    src={profile || avatar}
                    width="60px"
                    height="60"
                    style={{ borderRadius: "50%", background: "black" }}
                    alt="user"
                  />
                  <Typography variant="h4" style={{ fontWeight: "bold" }}>
                    {user_name}
                  </Typography>
                  {!userID && (
                    <ArrowForwardIosIcon style={{ marginLeft: "1rem" }} />
                  )}
                </Stack>

                <div className="d-flex flex-wrap">
                  {videos?.map((item, i) => (
                    <div className="pr-5 my-4">
                      <div
                        className={`${
                          videoID == item?.id && styles.videoSelected
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          getData([
                            { key: "user_id", value: user_id },
                            { key: "video_id", value: item?.id },
                          ]);
                        }}
                      >
                        <VideoCard
                          freelancerDetail={data}
                          unselectAll={unselectAll}
                          setUnSelectAll={setUnSelectAll}
                          info={item}
                          selectedVideos={selectedVideos}
                          setSelectedVideos={setSelectedVideos}
                          selectAll={selectAll}
                          index={i}
                          isBulkSelect={isBulkSelect}
                          getFreelancerDetails={refresh}
                          showPlayer={false}
                        />
                      </div>
                    </div>
                  ))}

                  {!videos?.length && (
                    <div className="text-center pb-3">
                      <img
                        src={NoVideo}
                        alt="no video"
                        className={`${styles.noVideo}`}
                      />
                      <p className="txt-lg txt-primary my-0">
                        Oops There is no Recorded Video
                      </p>
                    </div>
                  )}
                </div>
              </>
            ))}

          {!sortedUsers?.length && !showLoader && !areFiltersEmpty() && (
            <p className="txt-xlg txt-primary flex-grow-1 text-center">
              There are No Data!
            </p>
          )}
        </Box>
        <Box>
          {showVideo && videoURL && videoID && (
            <div style={{ width: "30rem" }}>
              {" "}
              <div
                className="pr-3"
                style={{
                  marginTop: "60px",
                  marginLeft: "auto",
                }}
              >
                <div style={{ position: "relative" }} className="pt-4">
                  <Typography
                    variant="h5"
                    style={{ position: "absolute", top: "-.6rem" }}
                  >
                    {file_name}
                  </Typography>

                  <VideoPlayer
                    video={videoURL}
                    thumbnail={thumbnail}
                    approval={approval}
                    id={id}
                    name={file_name}
                    refresh={refresh}
                    updateVideoApproval={updateVideoApproval}
                  />
                </div>
              </div>
            </div>
          )}
        </Box>
      </Stack>
    </>
  );
};

export default UserCard;
