import React from "react";
import {
  Backdrop,
  makeStyles,
  Snackbar as MuiSnackBar,
  Slide,
  SnackbarContent,
} from "@material-ui/core";
import CheckGIF from "assets/gif/SuccessGIf.gif";
import DeleteGIF from "assets/gif/DeletedGIF.gif";
import ErrorGIF from "assets/gif/ErrorGif.gif";
import { Stack, Typography } from "@mui/material";
import "./styles.scss";

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 999999,
    color: "#fff",
  },
}));

const Snackbar = (props) => {
  const { open, autoHideDuration, closeSnackBar, message, className } = props;
  const classes = useStyles();
  const snackbarprops = { ...props };
  delete snackbarprops.closeSnackBar;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    closeSnackBar(false);
  };

  const getIcon = () => {
    switch (className) {
      case "success":
        return (
          <img
            src={CheckGIF}
            alt=""
            style={{ width: "60px", height: "60px" }}
          />
        );
      case "delete":
        return (
          <img
            src={DeleteGIF}
            alt=""
            style={{ width: "60px", height: "60px" }}
          />
        );
      case "error":
        return (
          <img
            src={ErrorGIF}
            alt=""
            style={{ width: "60px", height: "60px" }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Backdrop
      className={`${classes.backdrop} RBG-Custom-Snackbar`}
      open={open}
      onClick={handleClose}
    >
      <MuiSnackBar
        open={open}
        autoHideDuration={autoHideDuration || 4000}
        TransitionComponent={SlideTransition}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        style={{
          minWidth: "13rem",
          maxWidth:'19rem',
          padding: 0,
        }}
      >
        <SnackbarContent
          style={{
            backgroundColor: "white",
            minWidth: "100px",
            border: "1px solid var(--error-main)",
            boxShadow: "none",
            padding: 0,
            overflow: "hidden",
          }}
          message={
            <Stack direction="row" alignItems="center" gap={2}>
              <span>{getIcon()}</span>
              <Typography
                color="black"
                variant="body2"
                textTransform="capitalize"
                fontWeight="bold"
                paddingRight='.5rem'
              >
                {message}
              </Typography>
            </Stack>
          }
        />
      </MuiSnackBar>
    </Backdrop>
  );
};
export default Snackbar;
