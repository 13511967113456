import React, { useContext, useEffect, useState } from "react";

import { Dialog, Typography } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";

import { Stack, Switch, TextField } from "@mui/material";
import { Box, Button } from "components";

// import styles from "./AutoDelete.module.scss";
import { getErrorMessage, trimObjectValues } from "utils/validator";
import { actions, GlobalContext } from "context";
import AuthServices from "api/services/auth-services";

const fieldErrors = {
  days: "Invalid days",
};

const AutoDelete = ({ open, handleClose, handleSnackbar }) => {
  const [enableSettings, setEnableSettings] = useState(false);
  const [days, setDays] = useState(null);
  const [error, setError] = useState(false);

  const { dispatch } = useContext(GlobalContext);

  const handleDays = (value) => {
    if (/^\d*$/.test(value)) {
      setDays(value);
    }

    if (value && Number(value) < 1) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const toggleEnableSetting = () => {
    setEnableSettings((pre) => !pre);
  };

  const validate = () => {
    const trimedValue = trimObjectValues({ days });
    let isInvalid = false;
    const inputFields = Object.keys(fieldErrors);
    inputFields.forEach((item) => {
      if (!trimedValue[item]) {
        isInvalid = true;
        setError(true);
        return;
      }
    });
    if (!isInvalid && !error) {
      updateGallerySettings();
    }
  };

  const updateGallerySettings = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const trimedValue = trimObjectValues({ auto_del: enableSettings, days: Number(days) });
      const { message } = await AuthServices.updateGallerySetting(trimedValue);
      storeHandler(actions.SHOW_LOADER, false);
      const content = {
        message: message,
        class: "success",
      };
      handleClose();
      handleSnackbar(true, content);
    } catch (err) {
      storeHandler(actions.SHOW_LOADER, false);
      const ERROR_MSG = getErrorMessage(err);
      const content = {
        message: ERROR_MSG,
        class: "error",
      };
      handleSnackbar(true, content);
    }
  };

  const getGallerySettings = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const { data } = await AuthServices.getGallerySetting();
      const {days, auto_del} = data || {}
      setDays(days);
      setEnableSettings(auto_del);
      storeHandler(actions.SHOW_LOADER, false);
    } catch (err) {
      storeHandler(actions.SHOW_LOADER, false);
      const ERROR_MSG = getErrorMessage(err);
      const content = {
        message: ERROR_MSG,
        class: "error",
      };
      handleSnackbar(true, content);
    }
  };

  useEffect(() => {
    getGallerySettings();
  }, []);

  const storeHandler = (type, payload) => dispatch({ type, payload });

  return (
    <Dialog
      PaperProps={{
        style: {
          borderRadius: "1rem",
          maxWidth: "500px",
        },
      }}
      open={open}
    >
      <Box className="p-3">
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            Gallery Settings
          </Typography>
          <CloseIcon
            style={{ fill: "var(--error-main)", cursor: "pointer" }}
            onClick={handleClose}
          />
        </Stack>

        <Stack direction="row" gap={1} className="mt-3 pl-2">
          <Typography variant="subtitle2">Gallery Settings</Typography>
          <Switch
            size="small"
            name="checkedA"
            color="error"
            checked={enableSettings}
            onClick={toggleEnableSetting}
          />
        </Stack>
        {enableSettings && (
          <Stack direction="row" gap={1} className="mt-3 pl-2">
            <Typography variant="subtitle2">
              Set the days for permanent file deletion, After the selected day,
              files will ve erased permanently
            </Typography>
            <div style={{ width: "10rem" }}>
              <TextField
                value={days}
                label="Days"
                size="small"
                color="error"
                onChange={(event) => {
                  const value = event.target.value;
                  handleDays(value);
                }}
                error={error}
                helperText={error && fieldErrors["days"]}
                sx={{
                  "& .MuiFormHelperText-root": {
                    margin: 0,
                  },
                }}
              />
            </div>
          </Stack>
        )}

        <Stack
          direction="row"
          gap={1}
          justifyContent="flex-end"
          className="mt-3"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={validate}
            style={{
              borderRadius: ".8rem",
            }}
          >
            Save
          </Button>

          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose}
            style={{
              borderRadius: ".8rem",
            }}
          >
            cancel
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default AutoDelete;
