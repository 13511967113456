import React, { useContext, useEffect, useState } from "react";

import { Chip, Typography } from "@material-ui/core";
import { Stack } from "@mui/material";
import { Box, Checkbox, ConfirmDialog, Snackbar } from "components";

import { getErrorMessage } from "utils/validator";
import { actions, GlobalContext } from "context";
import AuthServices from "api/services/auth-services";
import ButtonWithIcon from "components/IconButton/icon-button";
import { ReactComponent as FilterIcon } from "assets/images/filter.svg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import RecentDeleteCard from "./card/RecentDeleteCard";
import { useLocation } from "react-router-dom";

function createQueryString(year, month) {
  const yearString = year ? `year=${encodeURIComponent(year)}` : "";
  const monthString = month ? `month=${encodeURIComponent(month)}` : "";

  const queryParts = [yearString, monthString];

  return queryParts.filter((part) => part).join("&");
}

const RecentDelete = ({ refresh }) => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const year = queryParams.get("year");
  const month = queryParams.get("month");

  const {
    dispatch,
    state: { showLoader },
  } = useContext(GlobalContext);
  const [showVideo, setShowVideo] = useState(false);
  const [deletedVideoList, setDeletedVideoList] = useState([]);
  const [deletedVideoCount, setDeletedVideoCount] = useState(0);
  const [selectedItems, setSelectedItems] = useState(new Set());

  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({
    message: "",
    class: "error",
  });

  const [openDialog, setOpenDialog] = useState({
    open: false,
    message: "",
    type: "",
  });

  const handleCloseDialog = () => {
    setOpenDialog({
      open: false,
      message: "",
      type: "",
    });
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allSelected = new Set(deletedVideoList.map((item) => item.id));
      setSelectedItems(allSelected);
    } else {
      setSelectedItems(new Set());
    }
  };

  const handleToggleItem = (id) => {
    const newSelected = new Set(selectedItems);
    if (newSelected.has(id)) {
      newSelected.delete(id);
    } else {
      newSelected.add(id);
    }
    setSelectedItems(newSelected);
  };

  const isAllSelected =
    deletedVideoList.length > 0 &&
    selectedItems.size === deletedVideoList.length;

  const toggleShowVideo = () => {
    setShowVideo((pre) => !pre);
    setSelectedItems(new Set());
  };

  const deleteRecentVideo = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const { message } = await AuthServices.deleteRecentlyDeleteVideo({
        id: Array.from(selectedItems),
      });
      storeHandler(actions.SHOW_LOADER, false);
      setShowSnackBar(true);
      const content = {
        message: message,
        class: "success",
      };
      setSnackBarContent({ ...content });
    } catch (err) {
      storeHandler(actions.SHOW_LOADER, false);
      const ERROR_MSG = getErrorMessage(err);
      setShowSnackBar(true);
      const content = {
        message: ERROR_MSG,
        class: "error",
      };
      setSnackBarContent({ ...content });
    }
  };

  const restoreRecentVideo = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const { message } = await AuthServices.restoreRecentlyDeleteVideo({
        id: Array.from(selectedItems),
      });
      storeHandler(actions.SHOW_LOADER, false);
      setShowSnackBar(true);
      const content = {
        message: message,
        class: "success",
      };
      setSnackBarContent({ ...content });
    } catch (err) {
      storeHandler(actions.SHOW_LOADER, false);
      const ERROR_MSG = getErrorMessage(err);
      setShowSnackBar(true);
      const content = {
        message: ERROR_MSG,
        class: "error",
      };
      setSnackBarContent({ ...content });
    }
  };

  const getRecentDeleteVideo = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const queryString = createQueryString(year, month);
      const { data } = await AuthServices.getRecentlyDeleteVideo(queryString);
      setDeletedVideoList(data);
      setDeletedVideoCount(data?.length);
      storeHandler(actions.SHOW_LOADER, false);
    } catch (err) {
      storeHandler(actions.SHOW_LOADER, false);
      const ERROR_MSG = getErrorMessage(err);
      setShowSnackBar(true);
      const content = {
        message: ERROR_MSG,
        class: "error",
      };
      setSnackBarContent({ ...content });
    }
  };

  const getRecentDeleteVideoCount = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const queryString = createQueryString(year, month);
      const { del_count } = await AuthServices.getRecentlyDeleteVideoCount(
        queryString
      );
      setDeletedVideoCount(del_count);
      storeHandler(actions.SHOW_LOADER, false);
    } catch (err) {
      storeHandler(actions.SHOW_LOADER, false);
      const ERROR_MSG = getErrorMessage(err);
      setShowSnackBar(true);
      const content = {
        message: ERROR_MSG,
        class: "error",
      };
      setSnackBarContent({ ...content });
    }
  };

  useEffect(() => {
    if (showVideo) {
      getRecentDeleteVideo();
    }
  }, [showVideo]);

  useEffect(() => {
    setShowVideo(false);
    setSelectedItems(new Set());
    getRecentDeleteVideoCount();
  }, [year, month]);

  const storeHandler = (type, payload) => dispatch({ type, payload });

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between">
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          style={{
            width: "max-content",
            padding: ".6rem 1rem",
            borderRadius: ".3rem",
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
            textTransform: "capitalize",
            cursor: "pointer",
          }}
          onClick={toggleShowVideo}
        >
          <Typography>Recently Deleted</Typography>
          <Chip label={deletedVideoCount} size="small" color="primary" />
          <ArrowForwardIosIcon
            fontSize="small"
            style={{
              width: "1rem",
              color: "var(--error-main)",
              transform: `${showVideo ? "rotate(90deg)" : "rotate(0deg)"}`,
              transition: "all .2s ease",
            }}
          />
        </Stack>

        {!!deletedVideoList?.length && showVideo && (
          <Stack direction="row" alignItems="center" gap="1rem">
            <Stack direction="row" gap={0.5} mr={1}>
              <Checkbox
                size="small"
                checked={isAllSelected}
                onChange={handleSelectAll}
              />
              <Typography>Select All </Typography>
            </Stack>

            {!!selectedItems.size && (
              <ButtonWithIcon
                style={{
                  background: "white",
                  color: "var(--error-main)",
                  borderColor: "var(--error-main)",
                }}
                disableRipple
                startIcon={<FilterIcon fill="var(--error-main)" />}
                onClick={() => {
                  setOpenDialog({
                    open: true,
                    message: "Restore the selected Videos?",
                    type: "restore",
                  });
                }}
              >
                Restore
              </ButtonWithIcon>
            )}

            {!!selectedItems.size && (
              <ButtonWithIcon
                style={{
                  background: "white",
                  color: "var(--error-main)",
                  borderColor: "var(--error-main)",
                }}
                disableRipple
                startIcon={<FilterIcon fill="var(--error-main)" />}
                onClick={() => {
                  setOpenDialog({
                    open: true,
                    message: "Are you sure you want to Delete Permanently?",
                    type: "delete",
                  });
                }}
              >
                Delete
              </ButtonWithIcon>
            )}
          </Stack>
        )}
      </Stack>

      {showVideo && (
        <Stack direction="row" gap={"3rem"} flexWrap="wrap" className="py-4">
          {deletedVideoList?.map((data) => (
            <RecentDeleteCard
              info={data}
              handleToggleItem={handleToggleItem}
              selectedItems={selectedItems}
            />
          ))}

          {!deletedVideoList?.length && !showLoader && (
            <p className="txt-xlg txt-primary flex-grow-1 text-center">
              There are No Data!
            </p>
          )}
        </Stack>
      )}

      <ConfirmDialog
        onClose={handleCloseDialog}
        open={openDialog.open}
        message={openDialog.message}
        className={"download-conformbox"}
        onConfirm={() => {
          handleCloseDialog();
          if (openDialog.type === "delete") {
            deleteRecentVideo();
          } else {
            restoreRecentVideo();
          }
        }}
      />

      <Snackbar
        open={showSnackBar}
        message={snackbarContent?.message || ""}
        className={snackbarContent?.class || ""}
        autoHideDuration={4000}
        closeSnackBar={(val) => {
          setShowSnackBar(val);
          if (snackbarContent?.class !== "error") {
            getRecentDeleteVideoCount();
            getRecentDeleteVideo();
            refresh();
          }
        }}
      />
    </Box>
  );
};

export default RecentDelete;
