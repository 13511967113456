import React, { useContext, useEffect, useState } from "react";

// Browser Router
import { useHistory, useLocation } from "react-router-dom";

// MUI Components
import { makeStyles, Paper, Tooltip, Typography } from "@material-ui/core";

// Components
import YearMonthDayCard from "./year-month-day/YearMonthDayCard";

//Redux store
import { actions, GlobalContext } from "context";

//Styles
import styles from "./Gallery.module.scss";

//APIs
import AuthServices from "api/services/auth-services";
import UserCard from "./user-card/UserCard";
import ListView from "./list-vew/ListView";
import { Snackbar } from "components";
import { getErrorMessage } from "utils/validator";

import { ReactComponent as SettingIcon } from "assets/images/Settings.svg";
import AutoDelete from "./AutoDeleteSetting/AutoDelete";
import RecentDelete from "./recent-delete/RecentDelete";
import { checkBoolean } from "utils/videoUtils";

function addKeyValueToNestedObjects(obj, key, value) {
  if (typeof obj !== "object" || obj === null) {
    throw new TypeError("The first argument must be a non-null object.");
  }

  if (typeof key !== "string" || key.trim() === "") {
    throw new TypeError("The key must be a non-empty string.");
  }

  if (value === undefined) {
    throw new TypeError("The value must be defined.");
  }

  const newObj = {};

  for (const year in obj) {
    if (obj.hasOwnProperty(year)) {
      if (typeof obj[year] === "object" && obj[year] !== null) {
        newObj[year] = { ...obj[year], [key]: value };
      } else {
        console.warn(`Skipping ${year}: Not an object.`);
        newObj[year] = obj[year];
      }
    }
  }

  return newObj;
}

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    position: "relative",
    fontSize: ".7rem",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

const monthNameToNumber = (monthName) => {
  const monthMap = {
    jan: 1,
    feb: 2,
    mar: 3,
    apr: 4,
    may: 5,
    jun: 6,
    jul: 7,
    aug: 8,
    sep: 9,
    oct: 10,
    nov: 11,
    dec: 12,
  };

  const monthKey = monthName?.toLowerCase();

  return monthMap[monthKey] || null;
};

function Gallery() {
  const {
    dispatch,
    state: { showLoader, isAdmin },
  } = useContext(GlobalContext);
  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const year = queryParams.get("year");
  const month = queryParams.get("month");
  const day = queryParams.get("day");
  const user_id = queryParams.get("user_id");
  const video_id = queryParams.get("video_id");
  const view = queryParams.get("view");

  const [galleryData, setGalleryData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [videoPlayerByUserId, setVideoPlayerByUserId] = useState(null);

  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({
    message: "",
    class: "error",
  });

  const [gallerySetting, setGallerySetting] = useState(false);

  const toggleGallerySetting = () => {
    setGallerySetting((pre) => !pre);
  };

  const handleGallerySettingSnackbar = (state, message) => {
    setShowSnackBar(state);
    setSnackBarContent(message);
  };

  function updateVideoApproval(videoId, status) {
    let newApprovalValue;
    if (status === true) {
      newApprovalValue = true;
    } else if (status === false) {
      newApprovalValue = false;
    } else {
      newApprovalValue = null;
    }

    const updatedData = userData.map((user) => ({
      ...user,
      videos: user.videos.map((video) => {
        if (video.id === videoId) {
          return {
            ...video,
            approval: newApprovalValue,
          };
        }
        return video;
      }),
    }));

    setUserData(updatedData);
  }

  const cleanVideoURL = () => {
    setVideoPlayerByUserId(null);
  };

  const getAllVideo = async (value) => {
    storeHandler(actions.SHOW_LOADER, true);

    const determineFetchData = () => {
      const monthNameInNum = monthNameToNumber(month);
      if (year) {
        if (month) {
          if (day) {
            if (user_id) {
              return AuthServices.getUserGalleryVideo(
                year,
                monthNameInNum,
                day,
                user_id
              );
            }
            return AuthServices.getGalleryDay(year, monthNameInNum, day);
          }
          return AuthServices.getGalleryDays(year, monthNameInNum);
        }
        return AuthServices.getGalleryMonth(year);
      }
      return AuthServices.getGalleryYear();
    };

    try {
      setVideoPlayerByUserId(null);
      const fetchData = determineFetchData();
      const data = await fetchData;
      if (day || user_id) {
        setUserData(data?.data);
        setVideoPlayerByUserId(user_id);
        // setGalleryData([]);
      } else {
        const updatedData = addKeyValueToNestedObjects(data, "type", value);
        const desiredOrder = Object.keys(updatedData);
        desiredOrder?.sort((a, b) => parseInt(a) - parseInt(b));
        const sortedArray = desiredOrder?.map((key) => ({
          key,
          ...updatedData[key],
        }));

        setGalleryData(sortedArray || []);
        // setVideoPlayerByUserId(null);
        // setUserData([]);
      }

      storeHandler(actions.SHOW_LOADER, false);
    } catch (error) {
      storeHandler(actions.SHOW_LOADER, false);
      const ERROR_MSG = getErrorMessage(error);
      setShowSnackBar(true);
      const content = {
        message: ERROR_MSG,
        class: "error",
      };
      setSnackBarContent({ ...content });
    }
  };

  const handleNavigate = (params) => {
    const query = new URLSearchParams(location.search);

    params.forEach(({ key, value }) => {
      query.set(key, value);
    });

    history.push({ search: query.toString() });
  };

  const handleDeleteVideoIDQuery = () => {
    const query = new URLSearchParams(location.search);
    query.delete("video_id");
    history.push({ search: query.toString() });
  };

  const handleRefresh = (id) => {
    if (video_id === id) {
      handleDeleteVideoIDQuery();
    }
    handleGetGalleryVideo();
  };

  const handleGetGalleryVideo = () => {
    if (year && !month && !day) {
      getAllVideo("month");
    } else if (year && month && !day) {
      getAllVideo("day");
    } else if (year && month && day && !user_id) {
      getAllVideo();
    } else if (year && month && day && user_id) {
      getAllVideo();
    } else {
      getAllVideo("year");
    }
  };

  useEffect(() => {
    handleGetGalleryVideo();
  }, [year, month, day, user_id]);

  const storeHandler = (type, payload) => dispatch({ type, payload });

  return (
    <div className="pt-4">
      <Paper
        style={{
          borderRadius: "1rem",
          boxShadow:
            "-10px -10px 50px 0px #FFF inset, 10px 10px 50px 0px rgba(211, 211, 211, 0.25) inset, -18px -18px 48.5px 0px #FFF, 18px 18px 50px 0px rgba(173, 173, 173, 0.29)",
          border: "none",
        }}
        variant="outlined"
        className={`${styles.GalleryHeader} d-flex justify-content-between align-items-center flex-md-row flex-column `}
      >
        <Typography
          variant="h4"
          style={{ fontSize: "2.3rem", fontWeight: "600" }}
        >
          Gallery
        </Typography>
        {process.env.REACT_APP_RECENT_DELETE === 'true' && checkBoolean(isAdmin) && !day && (
          <BootstrapTooltip title={"Gallery Settings"} placement="left-start">
            <SettingIcon
              style={{ width: "25px", height: "25px", cursor: "pointer" }}
              onClick={toggleGallerySetting}
            />
          </BootstrapTooltip>
        )}
      </Paper>

      {!view && !day && (
        <YearMonthDayCard data={galleryData} getData={handleNavigate} />
      )}

      {!view && day && (
        <UserCard 
          data={userData}
          getData={handleNavigate}
          showVideo={videoPlayerByUserId}
          refresh={handleRefresh}
          cleanVideoURL={cleanVideoURL}
          updateVideoApproval={updateVideoApproval}
        />
      )}

      {view && <ListView refresh={handleRefresh} />}

      {((!galleryData?.length && !day) || (!userData?.length && day)) &&
        !view &&
        !showLoader && (
          <p className="txt-xlg txt-primary flex-grow-1 text-center">
            There are No Data!
          </p>
        )}

      {process.env.REACT_APP_RECENT_DELETE  === 'true' && checkBoolean(isAdmin) && !view && !day && (
        <>
          {gallerySetting && (
            <AutoDelete
              handleClose={toggleGallerySetting}
              handleSnackbar={handleGallerySettingSnackbar}
              open={gallerySetting}
            />
          )}

          <RecentDelete refresh={handleRefresh} />
        </>
      )}

      <Snackbar
        open={showSnackBar}
        message={snackbarContent?.message || ""}
        className={snackbarContent?.class || ""}
        autoHideDuration={4000}
        closeSnackBar={setShowSnackBar}
      />
    </div>
  );
}

export default Gallery;
