import {
  LOG_IN,
  DASHBOARD_DATA,
  ADMIN_DATA,
  FREELANCER_DATA,
  USER_ID,
  SHOW_LOADER,
  PROFILE_DETAILS,
  REFRESH_PROFILE,
  PROFILE,
  DOWNLOAD_QUEUE,
  CURRENT_DOWNLOAD_COUNT,
  COUNTRY_STATE,
  TIME_ZONE,
} from "./actions";

const initialState = {
  profile: {},
  isLoggedIn: false,
  isAdmin: false,
  dashboardData: {},
  adminData: [],
  freelancerData: [],
  userId: { admin: false },
  showLoader: 0,
  profileid: null,
  refreshProfile: false,
  download: {},
  currentDownload: 0,
  country_state: {},
  tz: {},
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case LOG_IN: {
      return {
        ...state,
        isLoggedIn: payload?.isLoggedIn,
        isAdmin: payload?.isAdmin,
      };
    }
    case PROFILE: {
      return {
        ...state,
        profile: payload,
      };
    }
    case TIME_ZONE: {
      return {
        ...state,
        tz: payload,
      };
    }
    case DASHBOARD_DATA: {
      return {
        ...state,
        dashboardData: { ...payload },
      };
    }
    case ADMIN_DATA: {
      return {
        ...state,
        adminData: [...payload],
      };
    }
    case FREELANCER_DATA: {
      return {
        ...state,
        freelancerData: [...payload],
      };
    }
    case USER_ID: {
      return {
        ...state,
        userId: { ...payload },
      };
    }
    case SHOW_LOADER: {
      if (payload === true) {
        return {
          ...state,
          showLoader: state.showLoader + 1,
        };
      } else if (payload === false) {
        return {
          ...state,
          showLoader: state.showLoader - 1,
        };
      }
      return state;
    }

    case PROFILE_DETAILS: {
      return {
        ...state,
        profileid: payload,
      };
    }
    case REFRESH_PROFILE: {
      return {
        ...state,
        refreshProfile: payload,
      };
    }
    case DOWNLOAD_QUEUE: {
      return {
        ...state,
        download: payload,
      };
    }

    case CURRENT_DOWNLOAD_COUNT: {
      return {
        ...state,
        currentDownload: payload,
      };
    }

    case COUNTRY_STATE: {
      return {
        ...state,
        country_state: payload,
      };
    }

    default:
      throw new Error();
  }
};

export { reducer, initialState };
