import axios from "axios";
import { getErrorMessage } from "utils/validator";
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_REST_API_URL,
});

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_REST_API_URL,
  headers: {
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*",
  },
});

const getAuthToken = async () => {
  const refreshToken = localStorage.getItem("refresh_token");
  return await axiosInstance
    .get("/create?func=refresh_token", {
      headers: {
        Authorization: `Bearer ${refreshToken}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.data);
};

const logoutUser = async () => {
  try {
    localStorage.clear();
    window.location.href = "/";
  } catch (error) {
    getErrorMessage(error);
  }
};

const onSuccess = (response) => Promise.resolve(response);
const onError = ({ response }) => {
  const originalRequest = response?.config;

  if (response?.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;

    return getAuthToken()
      .then(async ({ access_token }) => {
        localStorage.setItem("access_token", access_token);
        originalRequest.headers["Authorization"] = `Bearer ${access_token}`;
        return axiosInstance(originalRequest);
      })
      .catch((err) => {
        getErrorMessage(err);
        if (err.response?.status) {
          err.data = {
            message: "Token expired",
          };
        }
        localStorage.clear();
        setTimeout(() => (window.location.href = "/"), 3000);
        return Promise.reject(err);
      });
  }

  if (response?.status === 422) {
    logoutUser();
  }

  if (
    response?.status === 403 &&
    originalRequest?.url !== "/create?func=login"
  ) {
    setTimeout(() => {
      logoutUser();
    }, 4000)
  }

  if (response?.status === 500) {
    response.data = {
      message: "Internal Server Error",
    };
  }

  return Promise.reject(response);
};
httpClient.interceptors.request.use((req) => {
  req.headers = {};
  if (req.url === "/create?func=refresh_token") {
    req.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "refresh_token"
    )}`;
  } else {
    req.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "access_token"
    )}`;
  }
  return req;
});

httpClient.interceptors.response.use(onSuccess, onError);

export default httpClient;
