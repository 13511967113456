import { Box, Button, Pagination } from "@mui/material";
import { ReactComponent as SkipIcon } from "assets/images/skip.svg";
import { ReactComponent as SkipBackIcon } from "assets/images/skipback.svg";
import { ReactComponent as PageNextIcon } from "assets/images/pagenext.svg";
import { ReactComponent as PreviousIcon } from "assets/images/previous.svg";
import "./styles.scss";

const CustomButton = ({ ...rest }) => {
  return (
    <Button
      className="RSPagination-button"
      variant="outlined"
      size="small"
      sx={{
        textTransform: "capitalize",
        color: "black",
        backgroundColor: "white",
        borderColor: "lightgray",
        ":hover": {
          color: "inherit",
          backgroundColor: "white",
          borderColor: "lightgray",
        },
        ".MuiButton-icon": {
          paddingX: "5px",
        },
      }}
      {...rest}
    />
  );
};

const RBGPagination = ({ page, setPage, count }) => {
  const handleChange = (_, value) => {
    setPage(value - 1);
  };

  const handleNext = () => {
    setPage((pre) => pre + 1);
  };

  const handlePrevious = () => {
    setPage((pre) => pre - 1);
  };

  function nearestMultipleOf10Above() {
    const nextMultiple = Math.ceil((page + 2) / 10) * 10;
    setPage(Math.min(count - 1, nextMultiple - 1));
  }

  function nearestMultipleOf10Below() {
    if (page < 10) {
      setPage(0);
    } else {
      const prevPage = Math.floor(page / 10) * 10;
      setPage(Math.max(0, prevPage - 1));
    }
  }

  return (
    <Box className="RSPagination-root">
      {/* <CustomButton
        onClick={nearestMultipleOf10Below}
        disabled={count < 10 || page <= 0}
        startIcon={<SkipBackIcon color="error" />}
      >
        Skip
      </CustomButton> */}

      <CustomButton
        onClick={handlePrevious}
        disabled={page <= 0}
        startIcon={<PreviousIcon color="error" />}
      >
        Previous
      </CustomButton>

      <Pagination
        variant="outlined"
        shape="rounded"
        siblingCount={2}
        count={count}
        page={page + 1}
        hidePrevButton
        hideNextButton
        onChange={handleChange}
      />

      <CustomButton
        onClick={handleNext}
        disabled={page >= count - 1}
        endIcon={<PageNextIcon color="error" />}
      >
        Next
      </CustomButton>

      {/* <CustomButton
        onClick={nearestMultipleOf10Above}
        disabled={count < 10 || page >= count - 1}
        endIcon={<SkipIcon color="error" />}
      >
        Skip
      </CustomButton> */}
    </Box>
  );
};

export default RBGPagination;
