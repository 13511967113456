import { Box, Stack } from "@mui/material";
import { ConfirmDialog, InputField, Snackbar } from "components";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { ReactComponent as DownloadsIcon } from "assets/images/Downloads.svg";
import { ReactComponent as DeleteIcon } from "assets/images/list_delete.svg";
import { ReactComponent as FilterIcon } from "assets/images/list_view_filter.svg";
import { ReactComponent as GrideViewIcon } from "assets/images/grid_view.svg";
import RBGPagination from "components/pagination/RBGPagination";
import { actions, GlobalContext } from "context";
import AuthServices from "api/services/auth-services";
import ListViewTable from "./list-view-table/ListViewTable";
import ButtonBox from "app/Downloads/components/ButtonBox";
import Breadcrumb from "../breadcrumb/breadcrumb";
import ListViewFilter from "./list-view-filter/ListViewFilter";
import moment from "moment-timezone";
import RenamePopup from "app/freelancer-info/rename-popup/rename-popup";
import { getErrorMessage } from "utils/validator";
import { checkBoolean } from "utils/videoUtils";

const monthNameToNumber = (monthName) => {
  const monthMap = {
    jan: 1,
    feb: 2,
    mar: 3,
    apr: 4,
    may: 5,
    jun: 6,
    jul: 7,
    aug: 8,
    sep: 9,
    oct: 10,
    nov: 11,
    dec: 12,
  };

  const monthKey = monthName?.toLowerCase();

  return monthMap[monthKey] || null;
};

function createQueryString(
  filterCriteria,
  searchQuery,
  contractorFilters,
  year,
  month,
  page
) {
  const filterCriteriaString = filterCriteria
    .map((item) => {
      const val = item === "not reviewed" ? "pending" : item;
      return `filter_approval=${encodeURIComponent(val)}`;
    })
    .join("&");

  const contractorFilterString = contractorFilters
    .map((item) => {
      return `filter_cont=${encodeURIComponent(item)}`;
    })
    .join("&");

  const searchQueryString = `search=${encodeURIComponent(searchQuery)}`;
  const limitString = `limit=${encodeURIComponent(5)}`;

  const yearString = `year=${encodeURIComponent(year)}`;
  const monthString = month
    ? `month=${encodeURIComponent(monthNameToNumber(month))}`
    : "";
  const pageString = `page=${encodeURIComponent(page + 1)}`;

  const queryParts = [
    searchQueryString,
    limitString,
    filterCriteriaString,
    contractorFilterString,
    yearString,
    monthString,
    pageString,
  ];

  return queryParts.filter((part) => part).join("&");
}

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(null, args);
    }, delay);
  };
};

const ListView = ({ refresh }) => {
  const {
    dispatch,
    state: { showLoader, isAdmin },
  } = useContext(GlobalContext);

  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const year = queryParams.get("year");
  const month = queryParams.get("month");

  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState([]);
  const [selectedCopy, setSelectedCopy] = useState([]);
  const [selectedVideoRename, setSelectedVideoRename] = useState({
    status: false,
    data: {},
  });
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [totalpage, setTotalPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [searchQueryflag, setSearchQueryFlag] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({
    message: "",
    class: "error",
  });
  const [filterCriteria, setFilterCriteria] = useState([]);
  const [contractorFilters, setContractorFilters] = useState([]);

  const [renderKey, setRenderKey] = useState(0);

  useEffect(() => {
    setRenderKey(Date.now());
  }, [showLoader, rows]);

  const [openDialog, setOpenDialog] = useState({
    open: false,
    message: "",
    type: "",
  });

  const getListViewData = async (PAGE) => {
    const currentPage = PAGE != null ? PAGE : page;
    let fullQueryString = createQueryString(
      filterCriteria,
      searchText,
      contractorFilters,
      year,
      month,
      currentPage
    );

    storeHandler(actions.SHOW_LOADER, true);

    try {
      const { data, page, total_pages } = await AuthServices.getGalleryList(
        fullQueryString
      );

      data?.forEach((item) => {
        item.created_on =
          moment(item?.created_on).format("MM/DD/YYYY HH:mm:ss (z)") || "-";
      });
      setRows(data);
      setTotalPage(total_pages);
      setPage(page - 1);
      storeHandler(actions.SHOW_LOADER, false);
    } catch (err) {
      storeHandler(actions.SHOW_LOADER, false);
      const ERROR_MSG = getErrorMessage(err);
      setShowSnackBar(true);
      const content = {
        message: ERROR_MSG,
        class: "error",
      };
      setSnackBarContent({ ...content });
    }
  };

  const handleSetSearchQueryFlag = useRef(
    debounce(() => {
      setSearchQueryFlag((pre) => !pre);
      setPage(0);
    }, 500)
  ).current;

  const handleSearchChange = (value) => {
    setSearchText(value);
    handleSetSearchQueryFlag();
  };

  const handleCloseDialog = () => {
    setOpenDialog({
      open: false,
      message: "",
      type: "",
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelected = (val) => {
    setSelected(val);
    setSelectedCopy(val);
  };

  const handleAddVideo = (url) => {
    const currentDownloads =
      JSON.parse(localStorage.getItem("downloadQueue")) || [];
    currentDownloads.push(...url);
    localStorage.setItem("downloadQueue", JSON.stringify(currentDownloads));
    const event = new CustomEvent("localStorageChange", { key: Date.now() });
    window.dispatchEvent(event);
  };

  const downloadSelectedVideos = async () => {
    const groupName = `Group_${Date.now()}`;
    const filteredArr = rows?.filter((obj) => selectedCopy?.includes(obj?.id));
    filteredArr.forEach((item) => {
      item.group_name = groupName;
      item.completed = false;
      item.error = false;
    });
    let GROUPS = JSON.parse(localStorage.getItem("video_group")) || [];
    GROUPS.push(...filteredArr);
    localStorage.setItem("video_group", JSON.stringify(GROUPS));
    handleAddVideo(filteredArr);

    setSelected([]);
    setSelectedCopy([]);
    setOpenDialog({ message: "", open: false, type: "" });
  };

  const handleDownloadVideo = () => {
    downloadSelectedVideos();
  };

  const handleDeleteVideo = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    handleCloseDialog();
    try {
      const { message } = await AuthServices.deleteVideo({ id: selectedCopy });
      const data = {
        message: message || "Video successfully deleted",
        class: "delete",
      };
      setSelected([]);
      setSelectedCopy([]);
      storeHandler(actions.SHOW_LOADER, false);
      setShowSnackBar(true);
      setSnackBarContent({ ...data });
    } catch (err) {
      console.log(err);
      storeHandler(actions.SHOW_LOADER, false);
      const data = {
        message: err?.data?.message || "Something went worng!",
        class: "error",
      };
      setShowSnackBar(true);
      setSnackBarContent({ ...data });
    }
  };

  const handlePage = (val) => {
    setPage(val);
  };

  const handleFilterChange = (filter) => {
    setFilterCriteria((prev) => {
      if (prev.includes(filter)) {
        return prev.filter((item) => item !== filter);
      } else {
        return [...prev, filter];
      }
    });
    setPage(0);
  };

  const handleContractorFilters = (filter) => {
    setContractorFilters((prev) => {
      if (prev.includes(filter)) {
        return prev.filter((item) => item !== filter);
      } else {
        return [...prev, filter];
      }
    });
    setPage(0);
  };

  const handleHideListView = () => {
    const query = new URLSearchParams(location.search);
    query.delete("view");
    history.push({ search: query.toString() });
  };

  const rowVideoDownload = (val) => {
    setSelected([]);
    setSelectedCopy(val);
    setOpenDialog({
      open: true,
      message: "Are you sure you want to Download?",
      type: "download",
    });
  };

  const rowVideoDelete = (val) => {
    setSelected([]);
    setSelectedCopy(val);
    setOpenDialog({
      open: true,
      message: "Are you sure you want to Delete?",
      type: "delete",
    });
  };

  const handleRenamePopup = (val) => {
    setSelected([]);
    setSelectedCopy(val);
    const filteredArr = rows?.filter((obj) => val?.includes(obj?.id));
    setSelectedVideoRename({
      status: true,
      data: filteredArr?.[0] || {},
    });
  };

  const handleCloseRenamePopup = () => {
    setSelected([]);
    setSelectedCopy([]);
    setSelectedVideoRename({
      status: false,
      data: {},
    });
  };

  const handleNavigate = (row) => {
    const { created_month, created_year, created_day, cont_id, id } = row || {};

    if (!created_month || !created_year || !created_day || !cont_id || !id) {
      console.error("Missing required values:", {
        created_month,
        created_year,
        created_day,
        cont_id,
        id,
      });
      return;
    }

    const data = [
      { value: created_year, key: "year" },
      { value: created_month, key: "month" },
      { value: created_day, key: "day" },
      { value: cont_id, key: "user_id" },
      { value: id, key: "video_id" },
    ];

    const query = new URLSearchParams();
    data.forEach(({ key, value }) => {
      query.set(key, value);
    });

    history.push({ search: query.toString() });
  };

  useEffect(() => {
    getListViewData();

    return () => {
      if (typeof refresh === "function") {
        refresh();
      }
    };
  }, [filterCriteria, contractorFilters, searchQueryflag, page]);

  const storeHandler = (type, payload) => dispatch({ type, payload });

  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        className="py-4 px-2"
      >
        <Stack direction={"row"} alignItems={"center"} gap={"5rem"}>
          <Breadcrumb />
          <InputField
            style={{ width: "300px" }}
            value={searchText}
            onChange={(event) => {
              handleSearchChange(event.target.value);
            }}
            variant="filled"
            placeholder="Search by video name"
            withAdronment={true}
          />
        </Stack>

        <Stack direction={"row"} gap={4} alignItems="center">
          {!!selected?.length && checkBoolean(isAdmin) && (
            <ButtonBox
              variant="outlined"
              color="error"
              startIcon={<DownloadsIcon />}
              onClick={() =>
                setOpenDialog({
                  open: true,
                  message: "Are you sure you want to Download?",
                  type: "download",
                })
              }
            >
              Download
            </ButtonBox>
          )}

          {!!selected?.length && checkBoolean(isAdmin) && (
            <ButtonBox
              variant="outlined"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() =>
                setOpenDialog({
                  open: true,
                  message: "Are you sure you want to Delete?",
                  type: "delete",
                })
              }
            >
              Delete
            </ButtonBox>
          )}

          <FilterIcon
            style={{ width: "35px", cursor: "pointer" }}
            onClick={handleClick}
          />

          <GrideViewIcon
            style={{ width: "35px", cursor: "pointer", marginRight: "2rem" }}
            onClick={handleHideListView}
          />
        </Stack>
      </Stack>

      <ListViewFilter
        anchorEl={anchorEl}
        handleClose={handleClose}
        handleFilterChange={handleFilterChange}
        check={filterCriteria}
        handleContractorFilters={handleContractorFilters}
        contractorCheck={contractorFilters}
      />
      <Box>
        <ListViewTable
          key={renderKey}
          page={0}
          selected={selected}
          setSelected={handleSelected}
          rows={rows}
          rowVideoDownload={rowVideoDownload}
          rowVideoDelete={rowVideoDelete}
          handleRenamePopup={handleRenamePopup}
          handleNavigate={handleNavigate}
        />
        {rows?.length > 0 && totalpage > 1 && (
          <RBGPagination page={page} setPage={handlePage} count={totalpage} />
        )}

        <ConfirmDialog
          onClose={handleCloseDialog}
          open={openDialog.open}
          message={openDialog.message}
          className={"download-conformbox"}
          onConfirm={
            openDialog.type === "download"
              ? handleDownloadVideo
              : handleDeleteVideo
          }
        />

        {selectedVideoRename?.status && (
          <RenamePopup
            freelancerDetail={{}}
            filename={selectedVideoRename?.data?.file_name}
            open={selectedVideoRename?.status}
            id={selectedVideoRename?.data?.id}
            onConfirm={handleCloseRenamePopup}
            getFreelancerDetails={getListViewData}
          />
        )}

        <Snackbar
          open={showSnackBar}
          message={snackbarContent?.message || ""}
          className={snackbarContent?.class || ""}
          autoHideDuration={4000}
          closeSnackBar={(val) => {
            setShowSnackBar(val);
            if (snackbarContent?.class !== "error") {
              getListViewData(0);
            }
          }}
        />
      </Box>
    </>
  );
};

export default ListView;
