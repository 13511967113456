import { useState, useContext } from "react";
import { Box, TextField, Button, Snackbar } from "components";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import AuthServices from "api/services/auth-services";
import { GlobalContext, actions } from "context";
import { Paper, Typography, useTheme } from "@material-ui/core";
import { Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getErrorMessage } from "utils/validator";

const ChangePassword = ({ email }) => {
  const { dispatch } = useContext(GlobalContext);
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({
    message: "",
    class: "error",
  });
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [passwordMissmatch, setPasswordMissmatch] = useState(false);

  const history = useHistory();

  const validatePassword = () => {
    if (password === confirmPassword && password.length >= 8) {
      resetPassword();
    }
  };

  const resetPassword = async () => {
    setShowSnackBar(false);
    storeHandler(actions.SHOW_LOADER, true);

    const payload = {
      email,
      password,
      confirm_password: confirmPassword,
    };

    try {
      const { message } = await AuthServices.resetPassword(payload);
      storeHandler(actions.SHOW_LOADER, false);
      const data = {
        message: message || "Password Changes Successfully",
        class: "success",
      };
      setShowSnackBar(true);
      setSnackBarContent({ ...data });
      setTimeout(() => {
        history.push("/login");
      }, 1000);
    } catch (err) {
      storeHandler(actions.SHOW_LOADER, false);
      const MESSAGE = getErrorMessage(err);
      const data = { message: MESSAGE || "Please try again", class: "error" };
      setShowSnackBar(true);
      setSnackBarContent({ ...data });
    }
  };

  const passwordValidation = () => {
    setInvalidPassword(!!password && password.length < 8);
    setPasswordMissmatch(!!confirmPassword && password !== confirmPassword);
  };

  const storeHandler = (type, payload) => dispatch({ type, payload });

  return (
    <Paper
      variant="outlined"
      style={{
        borderRadius: ".7rem",
        overflow: "hidden",
      }}
    >
      <div>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          bgcolor={isDark ? "#cecece" : "#eef0f2"}
          py={"1rem"}
          px={"2rem"}
        >
          <Typography variant="h6">Password</Typography>
          <Link to="/login">
            <CloseIcon style={{ color: "var(--error-main)" }} />
          </Link>
        </Stack>
        <form autoComplete="off">
          <Stack direction="column" mx="2rem" my="1rem" mb={"2rem"} gap={1}>
            <Typography
              style={{ padding: "0 0 1rem 0", fontWeight: 500 }}
              variant="body1"
            >
              Set a strong password to prevent unauthorized access to your
              account.
            </Typography>
            <Box>
              <Typography variant="subtitle2">New Password</Typography>
              <TextField
                placeholder="Enter New Password"
                variant="outlined"
                fullWidth
                className={"mb-2"}
                passwordfield={true}
                onBlur={passwordValidation}
                onChange={(event) => setPassword(event.target.value)}
                helperText={invalidPassword && "Enter min 8 character"}
              />
            </Box>
            <Box>
              <Typography variant="subtitle2">Confirm New Password</Typography>
              <TextField
                placeholder="Confirm Password"
                variant="outlined"
                fullWidth
                className={"mb-3"}
                passwordfield={true}
                onBlur={passwordValidation}
                onChange={(event) => setConfirmPassword(event.target.value)}
                helperText={passwordMissmatch && "Password Mismatch"}
              />
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={validatePassword}
                disabled={!password || !confirmPassword}
              >
                Change Password
              </Button>
            </Box>
          </Stack>
        </form>
        <Snackbar
          open={showSnackBar}
          message={snackbarContent?.message || ""}
          className={snackbarContent?.class || ""}
          autoHideDuration={4000}
          closeSnackBar={setShowSnackBar}
        />
      </div>
    </Paper>
  );
};

export default ChangePassword;
