import React from "react";
import { createRoot } from "react-dom/client"; // Import createRoot
import "assets/styles/index.scss";
import App from "app/app";
import reportWebVitals from "reportWebVitals";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { theme } from "components";
import { BrowserRouter } from "react-router-dom";
import { GlobalProvider } from "context";

const registerServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      navigator.serviceWorker
        .register("/service-worker.js")
        .then((registration) => {
          console.log(
            "Service Worker registered with scope:",
            registration.scope
          );

          if (navigator.serviceWorker.controller) {
            console.log("Service Worker is controlling the page");
          } else {
            console.log("No Service Worker is controlling the page");
            window.location.reload();
          }
        })
        .catch((error) => {
          console.error("Service Worker registration failed:", error);
        });
    });
  } else {
    console.log("Service workers are not supported in this browser.");
  }
};

// Create a root for rendering
const container = document.getElementById("root");
const root = createRoot(container); // Create root using createRoot

root.render(
  <React.StrictMode>
    <GlobalProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <CssBaseline />
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </GlobalProvider>
  </React.StrictMode>
);

registerServiceWorker();
reportWebVitals();
