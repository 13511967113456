import React, { useContext, useState } from "react";

import {
  Dialog,
  Typography,
  TextField as TF,
  styled,
  Paper,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import moment from 'moment-timezone'
import { Autocomplete, Stack } from "@mui/material";
import { Box, Button } from "components";

import { getErrorMessage, trimObjectValues } from "utils/validator";
import { actions, GlobalContext } from "context";
import AuthServices from "api/services/auth-services";

const fieldErrors = {
  timezone: "Invalid timezone",
};

const AutoCompleteTextField = styled(TF)({
  "& .MuiOutlinedInput-root": {
    padding: ".25rem .3rem !important",
    borderBottom: "1.5px solid #D0D0D0",
    borderTop: "none",
    borderRight: "none",
    borderLeft: "none",
    borderRadius: "0",
    fontSize: ".9rem",
    "& fieldset": {
      border: "none",
    },
  },
});

const StyledPaper = styled(Paper)({
  boxShadow: "none !important",
  fontSize: "0.9rem",
  "& .MuiAutocomplete-listbox": {
    padding: 0,
    "& .MuiAutocomplete-option": {
      marginTop: "2px",
      backgroundColor: "white",
      border: "1px solid #c6c6c6",
      fontSize: "13px",
      textTransform: "capitalize",
    },
  },
});

const TimeZone = ({ open, handleClose, handleSnackbar, tz, allTimezone, setTz}) => {
  const [timezone, setTimeZone] = useState(tz);
  const [error, setError] = useState(false);
  const { dispatch } = useContext(GlobalContext);

  const validate = () => {
    const trimedValue = trimObjectValues({ timezone });
    let isInvalid = false;
    const inputFields = Object.keys(fieldErrors);
    inputFields.forEach((item) => {
      if (!trimedValue[item]) {
        isInvalid = true;
        setError(true);
        return;
      }
    });
    if (!isInvalid && !error) {
      updateTimeZone();
    }
  };

  const updateTimeZone = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const tz_name = timezone?.tz_name || "";
      const { message } = await AuthServices.updateTimeZone({
        tz_name,
      });
      setTz(timezone)
      moment.tz.setDefault(tz_name?.tz_name);
      storeHandler(actions.TIME_ZONE, timezone);
      storeHandler(actions.SHOW_LOADER, false);
      window.location.reload();
      const content = {
        message: message,
        class: "success",
      };
      handleSnackbar(true, content);
    } catch (err) {
      storeHandler(actions.SHOW_LOADER, false);
      const ERROR_MSG = getErrorMessage(err);
      const content = {
        message: ERROR_MSG,
        class: "error",
      };
      handleSnackbar(true, content);
    } finally {
      handleClose();
    }
  };

  const storeHandler = (type, payload) => dispatch({ type, payload });

  return (
    <Dialog
      PaperProps={{
        style: {
          borderRadius: "1rem",
          maxWidth: "450px",
        },
      }}
      open={open}
    >
      <Box className="p-3">
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            Settings
          </Typography>
          <CloseIcon
            style={{ fill: "var(--error-main)", cursor: "pointer" }}
            onClick={handleClose}
          />
        </Stack>

        <Stack direction="column" gap={1} className="mt-3 pl-2">
          <label className="txt-bold txt-md required-label">
            Select your Time Zone
          </label>

          <div>
            <Autocomplete
              disableClearable
              value={allTimezone.find((option) => option?.abbr === timezone?.abbr)}
              onChange={(_, value) => {
                setTimeZone(value);
              }}
              options={allTimezone}
              getOptionLabel={(option) =>
                `${option.tz_name} (${option.abbr}): ${option.offset}`
              }
              renderInput={(params) => (
                <AutoCompleteTextField
                  {...params}
                  size="small"
                  variant="outlined"
                  placeholder="Select Country"
                  helperText={error && fieldErrors["timezone"]}
                />
              )}
              componentsProps={{
                paper: {
                  component: StyledPaper,
                },
              }}
            />
          </div>
        </Stack>

        <Stack direction="row" gap={1} justifyContent="center" className="mt-3">
          <Button
            variant="contained"
            color="primary"
            onClick={validate}
            style={{
              padding: ".4rem 1.5rem",
              background:
                "linear-gradient(100deg, var(--error-main) 0%, rgba(255,135,135,1) 55%, var(--error-main) 100%)",
            }}
          >
            Save
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default TimeZone;
