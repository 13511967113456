import React from "react";
import {
  Checkbox,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Box,
  Stack,
} from "@mui/material";
import { ReactComponent as SortIcon } from "assets/images/sort.svg";
import { ReactComponent as UnCheckIcon } from "assets/images/uncheck.svg";
import { ReactComponent as CheckIcon } from "assets/images/checked.svg";
import "./styles.scss";
import { convertSecondsToHMS, formatBytes } from "utils/videoUtils";
import { Paper, Typography } from "@material-ui/core";

const CustomCheck = ({ ...rest }) => {
  return (
    <Checkbox
      checkedIcon={<CheckIcon width={"15px"} />}
      icon={<UnCheckIcon width={"15px"} />}
      {...rest}
    />
  );
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "file_name",
    numeric: false,
    disablePadding: true,
    label: "File Name",
  },
  {
    id: "memory",
    numeric: true,
    disablePadding: false,
    label: "Memory",
  },
  {
    id: "date",
    numeric: true,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "contractor",
    numeric: false,
    disablePadding: false,
    label: "Contractors",
  },
  {
    id: "duration",
    numeric: true,
    disablePadding: false,
    label: "Duration",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow sx={{ cursor: "pointer" }}>
        <TableCell padding="checkbox" sx={{ width: "70px" }}>
          <CustomCheck
            size="small"
            color="error"
            disableRipple
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            sx={{ p: 0.5 }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <Stack
              color="var(--text-primary)"
              direction={"row"}
              alignItems={"center"}
              gap={1}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              <SortIcon width={"8px"} />
            </Stack>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const RBGTable = ({ page, selected, setSelected, rows }) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const rowsPerPage = 5;

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (_, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage]
  );

  return (
    <Box sx={{ width: "100%", mb: 2, mt: 1 }}>
      <TableContainer
        className={`downloadTableBox`}
        component={Paper}
        style={{ border: "none" }}
        variant="outlined"
      >
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {visibleRows.map((row, index) => {
              const isItemSelected = isSelected(row.id);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                >
                  <TableCell padding="checkbox">
                    <CustomCheck
                      size="small"
                      disableRipple
                      color="error"
                      checked={isItemSelected}
                      sx={{ p: 0.5, cursor: "pointer" }}
                      onClick={(event) => handleClick(event, row.id)}
                    />
                  </TableCell>

                  <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    style={{
                      color: "var(--text-primary)",
                    }}
                  >
                    {row.file_name}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "var(--text-primary)",
                    }}
                  >
                    {formatBytes(row?.memory) || "-"}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "var(--text-primary)",
                    }}
                  >
                    {row.date}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "var(--text-primary)",
                    }}
                  >
                    {row.contractor}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "var(--text-primary)",
                    }}
                  >
                    {convertSecondsToHMS(row?.duration) || "-"}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "var(--text-primary)",
                    }}
                    className={
                      row.approval_status === true
                        ? "approved"
                        : row.approval_status === false
                        ? "rejected"
                        : "not-reviewed"
                    }
                    x
                  >
                    {row.approval_status === true
                      ? "Approved"
                      : row.approval_status === false
                      ? "Rejected"
                      : "Not reviewed"}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
          {!visibleRows?.length && (
              <TableRow
                style={{
                  height: 35 * 5,
                }}
              >
                <TableCell
                  colSpan={10}
                  align="center"
                  style={{ borderBottom: "none" }}
                >
                  <Typography variant="h6">No Data Available</Typography>
                </TableCell>
              </TableRow>
            )}
        </Table>
      </TableContainer>
    </Box>
  );
};

export default RBGTable;
