import React from "react";
import YearCard from "./year-card/year-card";
import Breadcrumb from "../breadcrumb/breadcrumb";
import { useLocation } from "react-router-dom";
import { ReactComponent as ListViewIcon } from "assets/images/list_view.svg";

// MUI Component
import { Stack } from "@mui/material";


const YearMonthDayCard = ({ data, getData }) => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const year = queryParams.get("year");
  const month = queryParams.get("month");

  const handleShowListView = () => {
    getData([{ value: 'list', key: 'view' }]);
  }

  return (
    <>
      <div className="pt-4 pb-3 pl-2">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Breadcrumb />
          {(year || month) && (
            <div style={{ marginRight: "2rem", cursor: "pointer" }}>
              <ListViewIcon style={{width:'35px'}} onClick={handleShowListView}/>
            </div>
          )}
        </Stack>
      </div>
      <Stack direction="row" gap={3} flexWrap="wrap" className="pl-2 py-2">
        {data?.map((item) => (
          <YearCard key={item?.key} data={item} getData={getData} />
        ))}
      </Stack>
    </>
  );
};

export default YearMonthDayCard;
