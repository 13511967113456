import { useState, useContext } from "react";
import styles from "../freelancer.module.scss";
import avatar from "assets/images/default.svg";
import {
  Button,
  InputField,
  TextField,
  Snackbar,
  DatePicker,
} from "components";
import AuthServices from "api/services/auth-services";
import { ReactComponent as UploadIcon } from "assets/images/upload.svg";
import CloseIcon from "@mui/icons-material/Close";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { GlobalContext, actions } from "context";
import {
  getErrorMessage,
  isEmailValid,
  trimObjectValues,
} from "utils/validator";
import { Autocomplete, Stack } from "@mui/material";
import ButtonWithIcon from "components/IconButton/icon-button";
import { Typography, TextField as TF, Paper, styled } from "@material-ui/core";
import { convertImageToBase64WithMetadata } from "utils/videoUtils";
import { INPUT_LIMITS } from "utils/constant";
import moment from 'moment-timezone';

const freelancerfieldErrors = {
  name: "Enter Name",
  email: "Enter Email",
  phone: "Enter phone number",
  date_joining: "Enter a valid date of joining",
  country: "Select Country",
  state: "Select State",
};

const FreelancerAutoCompleteTextField = styled(TF)({
  "& .MuiOutlinedInput-root": {
    padding: ".25rem .3rem !important",
    borderBottom: "1.5px solid #D0D0D0",
    borderTop: "none",
    borderRight: "none",
    borderLeft: "none",
    borderRadius: "0",
    fontSize: ".9rem",
    "& fieldset": {
      border: "none",
    },
  },
});

const freelancerStyledPaper = styled(Paper)({
  boxShadow: "none !important",
  fontSize: "0.9rem",
  "& .MuiAutocomplete-listbox": {
    padding: 0,
    "& .MuiAutocomplete-option": {
      marginTop: "2px",
      backgroundColor: "white",
      border: "1px solid #c6c6c6",
      fontSize: "13px",
      textTransform: "capitalize",
    },
  },
});

const AddFreelancer = ({ handleClose, open, getData }) => {
  const [newFreeLancer, setNewFreeLancer] = useState({});
  const [imageFile, setImageFile] = useState(null);
  const [image, setImage] = useState(null);
  const [isInvalidForm, setIsInvalidForm] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({
    message: "",
    class: "error",
  });
  const [invalidFileSize, setInvalidFileSize] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [minLengthInvalid, setMinLengthInvalid] = useState(false);

  const [phoneLenInvalid, setPhoneLenInvalid] = useState(null);
  const [altPhoneLenInvalid, setAltPhoneLenInvalid] = useState(null);

  const {
    dispatch,
    state: { country_state },
  } = useContext(GlobalContext);

  const COUNTRY_LIST = Object.keys(country_state);
  const STATE_LIST = country_state[newFreeLancer?.country || ""] || [];

  const addNewFreeLancer = (key, value) => {
    const val = value;
    const data = {
      ...newFreeLancer,
      [key]: key === "email" ? val.toLowerCase() : val,
    };
    setNewFreeLancer(data);
  };

  const validate = () => {
    const trimedValue = trimObjectValues(newFreeLancer);
    let isInvalid = false;
    const inputFields = Object.keys(freelancerfieldErrors);
    inputFields.forEach((item) => {
      if (!trimedValue[item]) {
        isInvalid = true;
        setIsInvalidForm(true);
        setNewFreeLancer(trimedValue);
        return;
      }

      if (item === "date_joining") {
        const dob = moment(trimedValue?.dob);
        const doj = moment(trimedValue?.date_joining);

        if (doj.isValid() && trimedValue?.dob) {
          if (dob.isSameOrAfter(doj, "day")) {
            isInvalid = true;
            setIsInvalidForm(true);
            addNewFreeLancer("date_joining", null);
            freelancerfieldErrors.date_joining =
              "The date of joining must be later than the date of birth.";
            return;
          }
        }
      }
    });
    if (!isInvalid) {
      const { email } = newFreeLancer;

      const isValidEmail = isEmailValid(email);
      setInvalidEmail(!isValidEmail);
      if (
        invalidEmail ||
        !isValidEmail ||
        minLengthInvalid ||
        altPhoneLenInvalid ||
        phoneLenInvalid
      ) {
        setIsInvalidForm(true);
      } else {
        setIsInvalidForm(false);
        setInvalidEmail(false);
        addFreelancer();
      }
    }
  };

  const addFreelancer = async () => {
    const base64Image = imageFile
      ? await convertImageToBase64WithMetadata(imageFile)
      : null;

    const payload = {
      ...newFreeLancer,
      profile: base64Image?.base64,
      content_type: base64Image?.content_type,
      filename: base64Image?.filename,
      ...(newFreeLancer.dob && { dob: dateFormat(newFreeLancer.dob) }),
      ...(newFreeLancer.date_joining && {
        date_joining: dateFormat(newFreeLancer.date_joining),
      }),
    };

    storeHandler(actions.SHOW_LOADER, true);

    try {
      const trimedValue = trimObjectValues(payload);
      const { message } = await AuthServices.addFreelancer(trimedValue);
      storeHandler(actions.SHOW_LOADER, false);
      setShowSnackBar(true);
      const content = {
        message: message,
        class: "success",
      };
      setSnackBarContent({ ...content });
    } catch (err) {
      storeHandler(actions.SHOW_LOADER, false);
      const ERROR_MSG = getErrorMessage(err);
      setShowSnackBar(true);
      const content = {
        message: ERROR_MSG || "Please try again later",
        class: "error",
      };
      setSnackBarContent({ ...content });
    }
  };

  const dateFormat = (val) => {
    const [month, date, year] = new Date(val).toLocaleDateString().split("/");
    return `${year}/${month}/${date}`;
  };

  const addImg = (event) => {
    if (
      !event.target ||
      !event.target.files ||
      event.target.files.length === 0
    ) {
      console.error("No file selected or invalid event target");
      return;
    }

    const img = event.target.files[0];

    if (!["image/jpeg", "image/jpg", "image/png"].includes(img.type)) {
      console.error("Selected file is not a JPEG or JPG image");
      setInvalidFileSize(true);
      return;
    }

    // Validate file size
    const fileSizeMB = img.size / 1024 / 1024;
    const isValidFileSize = fileSizeMB <= 2;

    if (isValidFileSize) {
      setImage(URL.createObjectURL(img));
      setImageFile(img);
      setInvalidFileSize(false);
    } else {
      setInvalidFileSize(true);
    }
  };

  const removeImg = () => {
    setImage(null);
    setImageFile(null);
    setInvalidFileSize(false);
  };

  const emailValidator = (event) => {
    const value = event.target.value;
    setInvalidEmail(!isEmailValid(value));
  };
  const textValidator = (event) => {
    const value = event.target.value;
    const invalid = value.length < 3 || value.length > 30;
    setMinLengthInvalid(invalid);
  };

  const phoneValidator = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    const invalid = value.length < 10 || value.length > 15;

    if (name === "phone") {
      if (value) {
        setPhoneLenInvalid(invalid);
      } else {
        setPhoneLenInvalid(null);
      }
    }

    if (name === "alternate_phone") {
      if (value) {
        setAltPhoneLenInvalid(invalid);
      } else {
        setAltPhoneLenInvalid(null);
      }
    }
  };

  const storeHandler = (type, payload) => dispatch({ type, payload });

  return (
    <>
      <Dialog
        PaperProps={{
          style: {
            borderRadius: "1rem",
          },
        }}
        maxWidth={"lg"}
        open={open}
        onClose={handleClose}
        fullWidth={true}
      >
        <DialogContent>
          <Stack
            mt={".5rem"}
            mb={"1.5rem"}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <p
              className="txt-primary txt-xlg m-0 px-md-3"
              style={{ fontWeight: "500" }}
            >
              Add Contractor
            </p>
            <ButtonWithIcon
              startIcon={<CloseIcon />}
              style={{ padding: ".5rem 1rem" }}
              onClick={handleClose}
            >
              Close
            </ButtonWithIcon>
          </Stack>
          <div className="row pb-4">
            <div className="d-flex flex-wrap  px-0 px-md-3 col-md-8 col-12">
              <div className="col-sm-6 col-12 mb-3">
                <label className="txt-bold txt-md required-label">Name</label>
                <TextField
                  variant="filled"
                  className={"mr-3"}
                  fullWidth
                  placeholder="Full Name"
                  alphanumeric={true}
                  onBlur={textValidator}
                  value={newFreeLancer.name || ""}
                  onChange={(event) => {
                    const name = event.target.value;
                    if (name.length <= INPUT_LIMITS.NAME) {
                      addNewFreeLancer("name", name);
                    }
                  }}
                  helperText={
                    (isInvalidForm &&
                      !newFreeLancer["name"] &&
                      freelancerfieldErrors["name"]) ||
                    (minLengthInvalid &&
                      (newFreeLancer["name"]?.length > 30
                        ? "Enter less than 30 characters"
                        : "Enter minimum 3 characters"))
                  }
                />
              </div>
              <div className="col-sm-6 col-12 mb-3">
                <label className="txt-bold txt-md required-label">Email</label>
                <InputField
                  variant="filled"
                  className={"mr-3"}
                  fullWidth
                  placeholder="Email Id"
                  onBlur={emailValidator}
                  value={newFreeLancer.email || ""}
                  onChange={(event) => {
                    const email = event.target.value;
                    if (email.length <= INPUT_LIMITS.EMAIL) {
                      addNewFreeLancer("email", email);
                    }
                  }}
                  helperText={
                    (isInvalidForm &&
                      !newFreeLancer["email"] &&
                      freelancerfieldErrors["email"]) ||
                    (invalidEmail && "Enter valid email id")
                  }
                />
              </div>

              <div className="col-sm-6 col-12 mb-3">
                <label className="txt-bold txt-md required-label">
                  Country
                </label>
                <Autocomplete
                  disableClearable
                  value={COUNTRY_LIST.find(
                    (option) => option === newFreeLancer["country"]
                  )}
                  onChange={(_, value) => {
                    addNewFreeLancer("country", value);
                  }}
                  options={COUNTRY_LIST}
                  renderInput={(params) => (
                    <FreelancerAutoCompleteTextField
                      {...params}
                      size="small"
                      variant="outlined"
                      placeholder="Select Country"
                      helperText={
                        isInvalidForm &&
                        !newFreeLancer["country"] &&
                        freelancerfieldErrors["country"]
                      }
                    />
                  )}
                  componentsProps={{
                    paper: {
                      component: freelancerStyledPaper,
                    },
                  }}
                />
              </div>
              <div className="col-sm-6 col-12 mb-3">
                <label className="txt-bold txt-md required-label">State</label>
                <Autocomplete
                  disableClearable
                  value={STATE_LIST?.find(
                    (option) => option === newFreeLancer["state"]
                  )}
                  onChange={(_, value) => {
                    addNewFreeLancer("state", value);
                  }}
                  options={STATE_LIST}
                  renderInput={(params) => (
                    <FreelancerAutoCompleteTextField
                      {...params}
                      size="small"
                      variant="outlined"
                      placeholder="Select State"
                      helperText={
                        isInvalidForm &&
                        !newFreeLancer["state"] &&
                        freelancerfieldErrors["state"]
                      }
                    />
                  )}
                  componentsProps={{
                    paper: {
                      component: freelancerStyledPaper,
                    },
                  }}
                />
              </div>

              <div className="col-sm-6 col-12 mb-3">
                <label className="txt-bold txt-md">Address</label>
                <InputField
                  variant="filled"
                  className={"mr-3"}
                  fullWidth
                  placeholder="Add Address"
                  value={newFreeLancer.address || ""}
                  onChange={(event) => {
                    const address = event.target.value;
                    if (address.length <= INPUT_LIMITS.ADDRESS) {
                      addNewFreeLancer("address", address);
                    }
                  }}
                />
              </div>

              <div className="col-sm-6 col-12 mb-3">
                <label className="txt-bold txt-md">ZIP</label>
                <TextField
                  variant="filled"
                  className={"mr-3"}
                  type="number"
                  fullWidth
                  placeholder="City zip code"
                  value={newFreeLancer.zipcode || ""}
                  onChange={(event) => {
                    const zipcode = event.target.value;
                    if (zipcode.length <= INPUT_LIMITS.ZIP) {
                      addNewFreeLancer("zipcode", zipcode);
                    }
                  }}
                />
              </div>
              <div className="col-sm-6 col-12 mb-3">
                <label className="txt-bold txt-md required-label">Phone</label>
                <TextField
                  variant="filled"
                  className={"mr-3"}
                  type="number"
                  fullWidth
                  name="phone"
                  onBlur={phoneValidator}
                  placeholder="Mobile Number"
                  value={newFreeLancer.phone || ""}
                  onChange={(event) => {
                    const phone = event.target.value;
                    if (phone.length <= INPUT_LIMITS.PHONE) {
                      addNewFreeLancer("phone", phone);
                    }
                  }}
                  helperText={
                    (isInvalidForm &&
                      !newFreeLancer["phone"] &&
                      freelancerfieldErrors["phone"]) ||
                    (phoneLenInvalid === true &&
                      "Phone Number must be between 10 and 15 digits.")
                  }
                />
              </div>

              <div className="col-sm-6 col-12 mb-3">
                <label className="txt-bold txt-md">Alternate Phone</label>
                <TextField
                  variant="filled"
                  className={"mr-3"}
                  fullWidth
                  type="number"
                  onBlur={phoneValidator}
                  name="alternate_phone"
                  placeholder="Alternate Mobile Number"
                  value={newFreeLancer.alternate_phone || ""}
                  onChange={(event) => {
                    const alternate_phone = event.target.value;
                    if (alternate_phone.length <= INPUT_LIMITS.PHONE) {
                      addNewFreeLancer("alternate_phone", alternate_phone);
                    }
                  }}
                  helperText={
                    altPhoneLenInvalid === true &&
                    "Phone Number must be between 10 and 15 digits."
                  }
                />
              </div>
              <div className="col-sm-6 col-12 mb-3">
                <label className="txt-bold txt-md">Date of Birth</label>
                <DatePicker
                  className="mt-0"
                  value={newFreeLancer?.dob || null}
                  onChange={(val) => addNewFreeLancer("dob", val)}
                />
              </div>

              <div className="col-sm-6 col-12 mb-3">
                <label className="txt-bold txt-md required-label">
                  Date of Joining
                </label>
                <DatePicker
                  className="mt-0"
                  value={newFreeLancer?.date_joining || null}
                  onChange={(val) => addNewFreeLancer("date_joining", val)}
                  helperText={
                    isInvalidForm &&
                    !newFreeLancer["date_joining"] &&
                    freelancerfieldErrors["date_joining"]
                  }
                />
              </div>
            </div>

            <div className="align-items-center col-12 col-md-4 d-flex flex-column flex-wrap">
              <div
                className={`d-flex flex-column justify-content-between ${styles.addImg}`}
              >
                <div className="text-center">
                  {" "}
                  <img
                    src={image ? image : avatar}
                    alt=""
                    className={styles.upload}
                  />
                </div>

                {!image && (
                  <ButtonWithIcon startIcon={<UploadIcon />}>
                    Upload Image
                    <TextField
                      className={styles.uploadImg}
                      inputProps={{
                        type: "file",
                        accept: "image/*",
                        id: "uploadImg",
                      }}
                      onChange={addImg}
                    />
                  </ButtonWithIcon>
                )}

                {image && (
                  <ButtonWithIcon onClick={removeImg} startIcon={<CloseIcon />}>
                    Remove Image
                  </ButtonWithIcon>
                )}
              </div>

              <Typography
                variant="caption"
                className=" my-1"
                style={{ color: "var(--text-disabled)" }}
              >
                Image size should be 2MB Max
              </Typography>

              {invalidFileSize && (
                <Typography
                  variant="caption"
                  style={{ color: "var(--error-main)" }}
                >
                  Upload a .jpg or .jpeg image up to 2 MB.
                </Typography>
              )}
            </div>
            <div className="col-12 text-center">
              <Button
                variant="contained"
                color="primary"
                className="mt-3"
                onClick={validate}
                style={{
                  padding: ".5rem 1rem",
                  background:
                    "linear-gradient(100deg, var(--error-main) 0%, rgba(255,135,135,1) 55%, var(--error-main) 100%)",
                }}
              >
                Submit
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Snackbar
        open={showSnackBar}
        message={snackbarContent?.message || ""}
        className={snackbarContent?.class || ""}
        autoHideDuration={4000}
        closeSnackBar={(val) => {
          setShowSnackBar(val);
          if (snackbarContent?.class !== "error") {
            handleClose();
            getData();
          }
        }}
      />
    </>
  );
};

export default AddFreelancer;
