import React, { useContext, useEffect, useState } from "react";
import { Button, InputField, Snackbar } from "components";
import {
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
} from "@material-ui/core";
import styles from "./rename-popup.module.scss";
import AuthServices from "api/services/auth-services";
import { GlobalContext, actions } from "context";
import { getErrorMessage, isFileNameValid } from "utils/validator";
import { VIDEO_INPUT_LIMITS } from "utils/constant";

const RenamePopup = ({
  open,
  onConfirm,
  id,
  filename,
  getFreelancerDetails,
  freelancerDetail,
}) => {
  const [name, setName] = useState(filename || "");
  const [nameEmpty, setNameEmpty] = useState(filename || "");
  const { dispatch } = useContext(GlobalContext);
  const [existingVideos, setExistingVideos] = useState([]);
  const [isNamePresent, setIsNamePresent] = useState(false);

  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({
    message: "",
    class: "error",
  });

  useEffect(() => {
    if (freelancerDetail?.video?.length) {
      let videoName = freelancerDetail?.video?.map((item) => item.name) || [];
      setExistingVideos(videoName);
    }
  }, []);

  const renameVideo = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const payload = {
        id,
        name,
      };
      const { message } = await AuthServices.renameVideo(payload);
      storeHandler(actions.SHOW_LOADER, false);
      const data = {
        message: message,
        class: "success",
      };
      setShowSnackBar(true);
      setSnackBarContent({ ...data });
    } catch (err) {
      onConfirm();
      storeHandler(actions.SHOW_LOADER, false);
      const ERROR_MSG = getErrorMessage(err);
      setShowSnackBar(true);
      const content = {
        message: ERROR_MSG,
        class: "error",
      };
      setSnackBarContent({ ...content });
    }
  };

  const validate = () => {
    const isNameExists = existingVideos.includes(name);
    if (isNameExists) {
      setIsNamePresent(true);
    } else {
      if (name) {
        setNameEmpty(false);
        setIsNamePresent(false);
        renameVideo();
      } else if (!name) {
        setNameEmpty(true);
      }
    }
  };

  const storeHandler = (type, payload) => dispatch({ type, payload });

  const handleClose = () => {
    onConfirm();
  };

  const updateName = (val) => {
    if (!isFileNameValid(val)) return;
    setName(val);
    isNamePresent && setIsNamePresent(false);
  };

  const handleClickInsideDialog = (event) => {
    event.stopPropagation();
    console.log("Clicked inside the dialog");
  };

  return (
    <>
      <Dialog
        open={open}
        className={styles.confirmDialog}
        maxWidth={"sm"}
        onClose={handleClose}
        onClick={handleClickInsideDialog}
      >
        <DialogContent>
          <DialogContentText className={styles.dialogContent}>
            <p className="mt-0 txt-bold txt-grey-drk">Rename File</p>
            <InputField
              variant="outlined"
              className={"mr-3"}
              fullWidth
              value={name || ""}
              onChange={(event) => {
                const video_name = event.target.value;

                if (video_name.length <= VIDEO_INPUT_LIMITS.NAME) {
                  updateName(video_name);
                }
              }}
              helperText={
                (isNamePresent && "Entered name already exists") ||
                (nameEmpty === true && "Video name is required.")
              }
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions className={styles.footerSec}>
          <Button
            variant="contained"
            color="primary"
            className={`btn-lg txt-lg ${styles.renameBtn}`}
            onClick={(e) => {
              e.stopPropagation();
              validate();
            }}
          >
            Rename
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={showSnackBar}
        message={snackbarContent?.message || ""}
        className={snackbarContent?.class || ""}
        autoHideDuration={4000}
        closeSnackBar={(val) => {
          setShowSnackBar(val);
          if (snackbarContent?.class !== "error") {
            getFreelancerDetails();
            onConfirm(null, null, true);
          }
        }}
      />
    </>
  );
};

export default RenamePopup;
